import Vue from 'vue'
import VueRouter from 'vue-router'
import { initRouterGuard } from '@/router/guards.js';

Vue.use(VueRouter)


const routeFiles = require.context('./async-routes', true, /\.js$/);

const asyncRoutes = routeFiles.keys().reduce((routes, routeFilePath) => {
  let value = routeFiles(routeFilePath).default;
  routes.push(value);
  return routes;
}, []);


export const routes = [
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    hidden: true,
    meta: {
      layout: 'NoLayout'
    }
  },
  ...asyncRoutes,
  { path: '*', redirect: '/404', hidden: true }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: "/ssd/",
  routes
})
initRouterGuard(router)
export default router
