export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'storeHome',
    name: "store",
    meta: {
        title: '首页',
        layout: 'LayHome'
    },
    children: [
        {
            path: 'storeHome',
            component: () => import('@/views/home/store/storeHome.vue'),
            name: "storeHome",
            meta: {
                layout: 'LayHome',
                userType: 3,
                chatShow: true,

            },
            pageType:1
         
        },

    ]
}