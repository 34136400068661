export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'storeIntroduce',
    name: "storeIntroducez",
    meta: {
        title: '商店',
        layout: 'NoLayout',
        userType: 2,
        name: "商品",
        pageType: 1,
    },
    children: [
        {
            path: 'storeIntroduce',
            component: () => import('@/views/seller/shop/store/storeIntroduce.vue'),
            name: "storeIntroduce",
            meta: {
                title: '商店介绍',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "商店介绍", path: false },
                ]
            },
        },
        {
            path: 'storeDecorate',
            component: () => import('@/views/seller/shop/store/storeDecorate.vue'),
            name: "storeDecorate",
            meta: {
                title: '商品装饰',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "商店装饰", path: false },
                ]
            },
        },
        {
            path: 'storeEvaluation',
            component: () => import('@/views/seller/shop/store/storeEvaluation.vue'),
            name: "storeEvaluation",
            meta: {
                title: '商品评价',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "商店评价", path: false },
                ]
            },
        },
        {
            path: 'editDecorate',
            component: () => import('@/views/seller/shop/store/editDecorate.vue'),
            name: "editDecorate",
            meta: {
                title: '',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "商店装饰", path: 'storeDecorate' },
                    { name: "电脑版店铺主页", path: false },
                ]
            },
        },

    ]
}