<template>
  <div class="laySon">
    <SonHead></SonHead>
    <div class="cell main-card">
      <div class="left">
        <div class="menu">
          <div v-for="(item, i) in infoData" :key="i" class="menu-item">
            <svg
              t="1655358522119"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2050"
              width="24"
              height="24"
              style="margin-right: 20px"
              v-if="item.value == 1"
            >
              <path
                d="M952.786158 352.800941c0-0.516042-0.172014-0.860071-0.172014-1.376113-0.344028-1.892155-0.860071-3.612296-1.548127-5.332437L875.895851 159.285066c-12.901058-38.531161-48.852007-62.613136-93.919704-62.613136L255.440954 96.67193c-44.207626 0-78.610449 23.565933-90.995464 60.720981L83.770872 347.984546c-0.344028 1.204099-0.860071 2.752226-1.204099 4.472367-5.84848 18.061482-8.77272 36.466991-8.77272 55.044515 0 65.193348 35.778935 124.71023 93.231648 155.328742 23.565933 12.729044 51.432219 19.093566 82.738787 19.093566 0 0 0 0 0.172014 0 52.464304-0.172014 101.144297-23.221905 134.34302-62.613136 33.026709 39.219217 81.19066 62.097094 133.654964 62.441122 51.948261-0.344028 100.112212-23.393919 132.966907-62.613136 33.198723 39.563245 81.878717 62.441122 134.687049 62.441122 31.994625-0.172014 60.204939-6.880564 84.1149-19.953637 56.592642-30.96254 91.683521-90.135394 91.683521-154.640685C961.386864 388.235847 958.29061 369.658324 952.786158 352.800941zM766.322862 383.935495 254.408869 383.935495c-17.717453 0-31.994625-14.277171-31.994625-31.994625s14.277171-31.994625 31.994625-31.994625l512.086007 0c17.717453 0 31.994625 14.277171 31.994625 31.994625S784.040316 383.935495 766.322862 383.935495z"
                p-id="2051"
                fill="#F6A261"
              ></path>
              <path
                d="M894.989417 639.892491c0-17.717453-14.277171-31.994625-31.994625-31.994625-3.268268 0-6.192508 0.860071-9.288762 1.892155l0 0c-23.737947 9.460776-54.012431 14.449185-76.890307 13.245087-50.400134-2.752226-88.931295-12.729044-125.914329-44.207626-36.466991 31.134554-83.082815 48.852007-132.622879 49.36805-50.22812-0.344028-97.187972-12.901058-133.826978-44.207626-36.983034 31.478582-84.1149 46.787838-134.34302 46.787838l-0.172014 0c-30.618512 0-47.991937-5.84848-75.858223-16.169326-0.688056-0.344028-1.548127-0.516042-2.408198-0.860071-1.376113-0.516042-2.408198-0.860071-3.78431-1.376113 0 0-4.644381-0.860071-7.052579-0.860071 0 0 0 0 0 0-17.717453 0-31.994625 14.449185-31.994625 31.994625l0.172014 171.498068c0 45.067697 36.81102 81.878717 82.050731 81.878717l602.221401-0.344028c45.067697-0.172014 81.878717-36.983034 81.878717-82.050731L894.989417 639.892491z"
                p-id="2052"
                fill="#F6A261"
              ></path>
            </svg>
            <svg
              t="1655359015888"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2301"
              width="24"
              height="24"
              style="margin-right: 20px"
              v-if="item.value == 2"
            >
              <path
                d="M518.966571 653.825634l0-6.70855c89.791366-64.161263 89.447337-175.282379 89.275323-265.589787l0-13.589115c0-130.730724-78.782463-208.653116-210.717285-208.653116l-6.70855 0c-126.602385 0-196.2681 74.138082-196.2681 208.653116 0 67.945574 0 205.728876 103.552495 279.178901l0 5.332437c-120.065849 17.029397-232.735092 66.225433-232.735092 144.663867 0 109.056946 107.680833 162.037292 329.062993 162.037292 283.30724 0 342.824122-88.071225 342.824122-162.037292C737.252478 713.170502 598.437091 669.13489 518.966571 653.825634z"
                p-id="2302"
                fill="#F6C73F"
              ></path>
              <path
                d="M929.564253 767.698975c-17.545439 0-31.82261-14.277171-31.994625-31.82261-0.344028-23.393919-101.660339-88.415253-197.300185-99.768184-16.169326-1.892155-28.210314-15.653284-28.210314-31.82261l0-66.225433c0-9.804804 4.472367-18.921552 12.040988-24.942046 54.012431-43.175542 94.951789-137.26726 94.951789-218.801949 0-109.917017-74.310096-132.966907-136.579204-132.966907-17.717453 0-31.994625-14.277171-31.994625-31.994625s14.277171-31.994625 31.994625-31.994625c123.678145 0 200.568453 75.514195 200.568453 197.128171 0 94.779775-44.207626 200.396439-106.992777 258.365194l0 24.598018c91.855535 19.609609 225.682513 83.942886 225.682513 158.59701 0 17.545439-14.277171 31.650596-31.82261 31.82261C929.736267 767.698975 929.736267 767.698975 929.564253 767.698975z"
                p-id="2303"
                fill="#F6C73F"
              ></path>
            </svg>
            <svg
              t="1655359108912"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2552"
              style="margin-right: 20px"
              v-if="item.value == 3"
              width="24"
              height="24"
            >
              <path
                d="M631.119772 619.422812l0.344028-16.685369c79.298505-55.388544 117.829666-153.092558 117.829666-297.756425 0-139.503444-87.211154-222.758273-233.423148-222.758273l-7.912649 0c-146.03998 0-233.423148 83.254829-233.423148 222.758273 0 143.975811 38.875189 241.679825 118.86175 297.412397l0 16.857383c-133.138922 17.889467-266.621871 70.869814-266.621871 151.88846 0 113.357299 129.526625 170.810012 385.139593 170.810012s385.139593-57.452713 385.139593-170.810012C897.053586 690.292626 763.914665 637.31228 631.119772 619.422812z"
                p-id="2553"
                fill="#9DB6F2"
              ></path>
            </svg>
            <span
              @click="handleClick(i)"
              :class="index == i ? 'active' : ''"
              style="cursor: pointer"
            >
              {{ item.name }}</span
            >
          </div>
        </div>
        <div class="menu-bottom" @click="toShopCenter">
          卖家中心
          <i class="el-icon-arrow-right" style="margin-left: 6px"></i>
        </div>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { setSAuth } from "@/utils/auth.js";
import SonHead from "./components/head/SonHead";
import { sellerLogin } from "@/api/main.js";
export default {
  components: {
    SonHead,
  },
  data() {
    return {
      infoData: [
        { name: "我的商家和店铺", value: 1 },
        // { name: "我的团队", value: 2 },
        // { name: "我的账户", value: 3 },
      ],
      index: 0,
    };
  },
  methods: {
    handleClick(index) {
      this.index = index;
    },
    toShopCenter() {
      sellerLogin().then((res) => {
        if (res.code == 1) {
   
          setSAuth(res.data.token);
          this.$router.push("shopCenter");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.laySon {
  background: #fafafa;
  height: 100%;
  .cell {
    display: flex;
    justify-content: space-between;
    .left {
      width: 240px;
      padding: 20px 0;
      .menu {
        border-bottom: 1px solid #eee;
        padding-bottom: 20px;
        .menu-item {
          display: flex;
          align-items: center;
          font-size: 16px;
          padding: 10px 0;
          .active {
            color: #ee4d2d;
          }
        }
      }
      .menu-bottom {
        padding: 10px 20px 10px 0;
        cursor: pointer;
        color: #666;
      }
    }
    .right {
      width: 966px;
    }
  }
}
</style>