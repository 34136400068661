<template>
  <div class="header-with-search-wrapper">
    <div class="header-with-search">
      <div style="margin-right: 40px">
        <img @click="handleJunp" style="height: 47px; cursor: pointer" :src="$store.state.baseInfo.front_logo" alt="" />
      </div>
      <div>
        <div class="home-input">
          <input v-model="$store.state.searchValue" @focus="focus" @blur="blur" @input="input" type="text" :placeholder="
              $store.state.searchType == 0 && $route.query.shop_id
                ? $t('homeNavbar.searchLanguageStore')
                : $t('homeNavbar.searchLanguage')
            " />
          <!-- 搜索切换 -->
          <div v-if="$route.query.shop_id" @mouseenter="aatext" @mouseleave="aatext" style="
              font-size: 14px;
              width: 145px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 20px;
              height: 90%;
              border-left: 1px solid rgba(0, 0, 0, 0.1);
              color: rgba(0, 0, 0, 0.8);
              position: relative;
              cursor: pointer;
            ">
            {{ xxArr[$store.state.searchType].label }}
            <i class="el-icon-arrow-down"></i>
            <div v-if="popShow" style="
                position: absolute;
                top: 10px;
                left: 0;
                padding-top: 30px;
                z-index: 2;
              ">
              <div style="
                  background: #fff;
                  width: 120px;
                  border-radius: 2px;
                  font-size: 14px;
                  overflow: hidden;
                  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
                ">
                <div @click="handelSwitch(i)" v-for="(item, i) in xxArr" :key="i" class="poi" style="
                    height: 45px;
                    line-height: 45px;
                    padding: 0 10px 0 15px;
                  ">
                  {{ item.label }}
                  <i style="color: #ee4d2d" class="el-icon-check" v-if="i == $store.state.searchType"></i>
                </div>
              </div>
            </div>
          </div>
          <el-button @click="keywordSearch" type="primary" icon="el-icon-search" style="
              width: 60px;
              max-height: 34px;
              line-height: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #fb5533;
            ">
          </el-button>
          <div v-if="show" class="ipt-line" :style="{ width: $route.query.shop_id ? '672px' : '780px' }"></div>
          <!-- 本地记录 -->
          <div class="hist-log" v-if="show" :style="{ width: $route.query.shop_id ? '652px' : '757px' }">
            <div @mousedown="searchStore" class="title" v-if="$store.state.searchValue">
              <svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" stroke-width="0"
                class="shopee-svg-icon" style="width: 16px; height: 16px; margin-right: 10px" fill="#ee4d2d">
                <path
                  d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z">
                </path>
              </svg>
              <div>
                搜寻
                <span>"{{ $store.state.searchValue }}"商店</span>
              </div>
            </div>
            <div class="titles" v-if="
                $store.state.searchType == 0 &&
                $route.query.shop_id &&
                searchHist.length != 0
              ">
              搜寻记录
            </div>
            <!-- <div v-else>dasdad</div> -->
            <div @mousedown="handleClicks(item)" class="item" v-for="item in searchHist" :key="item.id">
              {{ item.keyword }}
            </div>
          </div>
        </div>
        <!-- 搜索排行 -->
        <div class="record" style="font-size: 12px; color: #fff; margin-top: 8px">
          <span v-for="(item, i) in $store.state.search_module" :key="i">
            <a href="javascript:;" @click="handleClick(item)">
              {{ item.keywords }}</a>
          </span>
        </div>
      </div>
      <div style="width:100%;display: flex;
    align-items: center;
    justify-content: center;
">
        <!-- <el-popover placement="bottom-end" width="400" trigger="hover" > 
          <div class="">
            <el-empty :image="shopCart" description="尚无商品"></el-empty>
          </div> -->
        <div slot="reference" style="position: relative">
          <i class="el-icon-shopping-cart-2" style="color: #fff; font-size: 30px; cursor: pointer" @click="toPush"></i>
          <div style="
              position: absolute;
              right: -12px;
              top: -4px;
              font-size: 12px;
              color: #ee4d2d;
              background: #fff;
              padding: 0 6px;
              border-radius: 60%;
            ">
            {{ $store.state.buyCartLength }}
          </div>
        </div>
        <!-- </el-popover> -->
      </div>
    </div>
  </div>
</template>
<script>
  // import { getBAuth } from "@/utils/auth.js";
  import { getBuyCartLength, setSearch } from "@/api/home.js";
  export default {
    data() {
      return {
        searchInfo: "",
        show: false,
        arr: [
          "Mask",
          "Water Bottle",
          "Dress",
          "Phone Case",
          "Bag",
          "iPhone 13 Pro Max Case",
          "Apple Watch Strap",
          "Bluetooth Earphone",
        ],

        data: [
          {
            title: "商家名称1",
            shop_id: 1,
            goodsList: [],
          },
          {
            title: "商家名称2",
            shop_id: 1,
            goodsList: [],
          },
        ],
        value: "",
        searchHist: [],
        xxArr: [
          {
            label: this.$t("homeNavbar.searchLanguageShop"),
          },
          {
            label: "在Shopee",
          },
        ],
        index: 0,
        popShow: false,
      };
    },
    created() {
      // if (getBAuth()) {
      this.getCartNum();
      // }
    },
    methods: {
      focus() {
        this.show = true;
        if (localStorage.SEARCHHISTORY) {
          this.searchHist = JSON.parse(localStorage.SEARCHHISTORY).reverse();
        }
      },
      blur() {
        this.show = false;
      },
      input() {
        let arr = [];

        let data = localStorage.SEARCHHISTORY
          ? JSON.parse(localStorage.SEARCHHISTORY)
          : [];
        if (this.$store.state.searchValue != "") {
          data.forEach((item) => {
            if (item.keyword.includes(this.$store.state.searchValue)) {
              arr.push(item);
            }
          });
          this.searchHist = arr.reverse();
        } else {
          this.searchHist = data.reverse();
        }
      },
      toPush() {
        this.$router.push("buyCart");
      },
      getCartNum() {
        getBuyCartLength().then((res) => {
          if (res.code == 1) {
            this.$store.state.buyCartLength = res.data.cart_num;
          }
          if (res.code == -1) {
            this.$store.state.buyCartLength = 0;
          }
        });
      },
      keywordSearch() {
        if (this.$store.state.searchType == 1 && this.$route.query.shop_id) {
          this.$router.push({ query: { shop_id: "" } });
        }

        if (this.$store.state.searchValue == "") {
          return false;
        }
        let arr = localStorage.SEARCHHISTORY
          ? JSON.parse(localStorage.SEARCHHISTORY)
          : [];

        if (!this.has(arr, this.$store.state.searchValue)) {
          arr.push({
            id: arr.length + 1,
            keyword: this.$store.state.searchValue,
          });
        }
        localStorage.SEARCHHISTORY = JSON.stringify(arr);
        this.searchRecord();
      },
      searchStore() {
        if (this.$route.name == "storeSearch") {
          location.reload();
        } else {
          this.$router.push("storeSearch");
        }
      },
      handleClick(value) {
        this.$store.state.searchValue = value.keyword
          ? value.keyword
          : value.keywords;
        sessionStorage.CATEGORYID = "";
        this.searchRecord();
        if (this.$route.name == "goodsSearch") {
          location.reload();
        } else {
          this.$router.push({
            path: "goodsSearch",
          });
        }
      },
      handleClicks(value) {
        if (this.$store.state.searchType == 1 && this.$route.query.shop_id) {
          this.$router.push({ query: { shop_id: "" } });
        }
        this.$store.state.searchValue = value.keyword
          ? value.keyword
          : value.keywords;
        sessionStorage.CATEGORYID = "";
        this.searchRecord();
        if (this.$route.name == "goodsSearch") {
          location.reload();
        } else {
          this.$router.push({
            path: "goodsSearch",
            query: { shop_id: this.$route.query.shop_id },
          });
        }
      },

      searchRecord() {
        setSearch({
          keywords: this.$store.state.searchValue,
        }).then((res) => {
          if (res.code == 1) {
            sessionStorage.CATEGORYID = "";

            if (this.$route.name == "goodsSearch") {
              location.reload();
            } else {
              this.$router.push({
                path: "goodsSearch",
                query: { shop_id: this.$route.query.shop_id },
              });
            }
          }
        });
      },
      has(arr, name) {
        const result = arr.filter((item) => item.keyword === name);
        if (result.length === 0) {
          return false;
        }
        return true;
      },
      handelSwitch(index) {
        this.$store.state.searchType = index;
      },
      aatext() {
        this.popShow = this.popShow ? false : true;
      },
      handleJunp() {
        if (this.$route.path != "/home") {
          this.$router.replace("/");
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  .header-with-search-wrapper {
    width: 1240px;
    margin: 0 auto;

    .header-with-search {
      height: 85px;
      padding: 16px 0 10px;
      display: flex;

      // justify-content: space-between;
      .icon-shopee-logo {
        width: 162px;
        height: 50px;
      }
    }

    .home-input {
      width: 840px;
      height: 40px;
      background: #fff;
      padding: 3px;
      display: flex;
      align-items: center;
      border-radius: 2px;
      position: relative;

      .poi {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }

      .poi:hover {
        background: #fbfbfb;
      }

      input {
        width: 100%;
        height: 100%;
        border: none;
        padding: 0 10px;
        background: transparent;
        position: relative;
        z-index: 2;

        // text-transform: lowercase;
      }

      .ipt-line {
        // width: 780px;
        // width: 672px;
        height: 52px;
        left: -6px;
        border: 2px solid #000;
        position: absolute;
        border-radius: 4px;
      }

      .hist-log {
        position: absolute;
        width: 757px;
        top: 38px;
        margin-top: 0.5rem;
        margin-left: -0.1875rem;
        background-color: #fff;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        overflow: hidden;
        z-index: 5;

        .title {
          font-size: 14px;
          // color: #00000042;
          padding: 6px 10px;
          display: flex;
          align-content: center;
        }

        .titles {
          font-size: 14px;
          color: #00000042;
          padding: 6px 10px;
        }

        .title:hover {
          background: #fafafa;
          cursor: pointer;
        }

        .item {
          padding: 10px;
          cursor: pointer;
          font-size: 14px;
        }

        .item:hover {
          background: #fafafa;
        }
      }
    }

    .record {
      color: #ffffe6;

      span {
        margin-right: 14px;
      }

      // span:hover {
      //   // cursor: pointer;
      // }
    }
  }
</style>