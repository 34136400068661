import Vue from 'vue'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/theme/index.css'
import App from './App.vue'
import router from './router'   //路由
import store from './store'   //状态管理
import VueI18n from 'vue-i18n'        //语言包
import Toast from "@/components/Toast.vue";    //自定义toast
import Communication from "@/components/Communication";
// import 'default-passive-events'
import SlideVerify from 'vue-monoplasty-slide-verify';  //图形验证码
import VueSmsCode from 'vue-sms-check-code'      //短信验证码

// 字体样式
import '@/assets/css/font/index.css'

import filters from "@/filters/index" //过滤器
import { formatTime, formatTimeS, formatTimeA } from "@/filters/index.js"  //方法
import { Base64 } from 'js-base64';  //base64
Vue.prototype.$Base64 = Base64
Vue.prototype.formatTime = formatTime
Vue.prototype.formatTimeS = formatTimeS
Vue.prototype.formatTimeA = formatTimeA
// 添加全局filter
Object.keys(filters).map(v => {
  Vue.filter(v, filters[v])
})

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueI18n);
Vue.use(SlideVerify);
Vue.use(VueSmsCode);



Vue.component('Toast', Toast)
Vue.component('Communication', Communication)
const i18n = new VueI18n({
  locale: 'zh',  // 语言标识
  messages: {
    'zh': require('./i18n/lang/zh.js'),
    'en': require('./i18n/lang/en.js')
  },

  silentTranslationWarn: true,
})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
