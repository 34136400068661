export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'seller',
    name: "sellerz",

    meta: {
        title: '申请入驻出海东南亚电商平台 | Shopee虾皮',
        layout: 'NoLayout',
        userType: 1,
    },
    children: [
        {
            path: 'seller',
            component: () => import('@/views/seller/seller.vue'),
            name: "seller",
          
            meta: {
                title: '申请入驻出海东南亚电商平台 | Shopee虾皮',
                layout: 'NoLayout',
                userType: 1,
            },
        }

    ]
}