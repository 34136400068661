import request from '@/api/request.js';
// 买家注册
export function buyRegister(data) {
    return request({
        url: 'buyers/api/user/register',
        method: 'post',
        data: data
    });
}
// 买家登录

export function buyLogin(data) {
    return request({
        url: 'buyers/api/user/login',
        method: 'post',
        data: data
    });
}
// 买家退出

export function logout(data) {
    return request({
        url: 'buyers/api/user/logout',
        method: 'post',
        data: data
    });
}
// 获取买家信息

export function buyUserInfo(data) {
    return request({
        url: 'buyers/api/user/userInfo',
        method: 'get',
        params: data
    });
}
//更改基本信息

export function updateUserInfo(data) {
    return request({
        url: 'buyers/api/user/updateUserInfo',
        method: 'post',
        data: data
    });
}
//修改头像

export function editUserAvatar(data) {
    return request({
        url: 'buyers/api/user/editUserAvatar',
        method: 'post',
        data: data
    });
}
//修改电邮

export function editUserEmail(data) {
    return request({
        url: 'buyers/api/user/editUserEmail',
        method: 'post',
        data: data
    });
}
//修改电话号码

export function editUserMobile(data) {
    return request({
        url: 'buyers/api/user/editUserMobile',
        method: 'post',
        data: data
    });
}

//我的地址
export function addressList(data) {
    return request({
        url: 'buyers/api/user/addressList',
        method: 'get',
        params: data
    });
}
//新增编辑地址
export function operateAddress(data) {
    return request({
        url: 'buyers/api/user/operateAddress',
        method: 'post',
        data: data
    });
}
//新增编辑地址
export function deleteAddress(data) {
    return request({
        url: 'buyers/api/user/deleteAddress',
        method: 'delete',
        data: data
    });
}

//忘记密码
export function forgotPassword(data) {
    return request({
        url: 'buyers/api/user/forgotPassword',
        method: 'post',
        data: data
    });
}
//更改密码
export function changePassword(data) {
    return request({
        url: 'buyers/api/user/changePassword',
        method: 'post',
        data: data
    });
}
// 购买清单
export function purchaseList(data) {
    return request({
        url: 'buyers/api/order/purchaseList',
        method: 'post',
        data: data
    });
}

// 确认订单
export function getOrderGoodsList(data) {
    return request({
        url: 'buyers/api/order/getOrderGoodsList',
        method: 'post',
        data: data
    });
}
// 提交订单
export function addOrder(data) {
    return request({
        url: 'buyers/api/order/addOrder',
        method: 'post',
        data: data
    });
}
// 支付订单
export function payment(data) {
    return request({
        url: 'buyers/api/order/payment',
        method: 'post',
        data: data
    });
}

// 购买清单-订单详情
export function getOrderInfo(data) {
    return request({
        url: 'buyers/api/order/getOrderInfo',
        method: 'get',
        params: data
    });
}

// 取消订单
export function cancelOrder(data) {
    return request({
        url: 'buyers/api/order/cancelOrder',
        method: 'post',
        data: data
    });
}
// 确认收货
export function confirmOrder(data) {
    return request({
        url: 'buyers/api/order/confirmOrder',
        method: 'post',
        data: data
    });
}
// 确认收货
export function commentOrder(data) {
    return request({
        url: 'buyers/api/order/commentOrder',
        method: 'post',
        data: data
    });
}

// 确认收货
export function getRefundOrder(data) {
    return request({
        url: 'buyers/api/refund/getRefundOrder',
        method: 'get',
        params: data
    });
}
// 确认收货
export function addApply(data) {
    return request({
        url: 'buyers/api/refund/addApply',
        method: 'post',
        data: data
    });
}

// 确认收货
export function editOrderAddress(data) {
    return request({
        url: 'buyers/api/order/editOrderAddress',
        method: 'post',
        data: data
    });
}

// 通知-订单更新
export function notice(data) {
    return request({
        url: 'buyers/api/order/notice',
        method: 'get',
        params: data
    });
}
// 通知-订单更新
export function article(data) {
    return request({
        url: 'buyers/api/article/index',
        method: 'get',
        params: data
    });
}
// 通知-订单更新
export function articleDetail(data) {
    return request({
        url: 'buyers/api/article/detail',
        method: 'get',
        params: data
    });
}
// 我的优惠券列表
export function getMyList(data) {
    return request({
        url: 'buyers/api/coupon/getMyList',
        method: 'get',
        params: data
    });
}
// 推荐优惠券列表
export function recommendCoupons(data) {
    return request({
        url: 'buyers/api/coupon/recommendCoupons',
        method: 'get',
        params: data
    });
}
// 获取店铺优惠券列表
export function getShopCouponList(data) {
    return request({
        url: 'buyers/api/coupon/getShopCouponList',
        method: 'get',
        params: data
    });
}
// 领取优惠券
export function couponReceive(data) {
    return request({
        url: 'buyers/api/coupon/receive',
        method: 'post',
        data: data
    });
}

// 获取限时选购基本信息
export function getLimitPurchase(data) {
    return request({
        url: 'buyers/api/index/getLimitPurchase',
        method: 'get',
        params: data
    });
}
// 获取限时选购商品

export function getLimitPurchaseGoods(data) {
    return request({
        url: 'buyers/api/index/getLimitPurchaseGoods',
        method: 'get',
        params: data
    });
}

// 购物车获取更多优惠券

export function getAllCoupon(data) {
    return request({
        url: 'buyers/api/coupon/getAllCoupon',
        method: 'get',
        params: data
    });
}

// 店铺营业状态
export function shopState(data) {
    return request({
        url: 'buyers/api/shop/shopState',
        method: 'get',
        params: data
    });
}
// 店铺 - 4.6-获取店铺装修

export function getDecoration(data) {
    return request({
        url: 'buyers/api/shop/getDecoration',
        method: 'get',
        params: data
    });
}