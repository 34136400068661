var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MainHead'),(
      _vm.$route.name != 'buyHome' &&
      _vm.$route.name != 'buyAddress' &&
      _vm.$route.name != 'buyEditPwd' &&
      _vm.$route.name != 'buyListing' &&
      _vm.$route.name != 'buyOrderInfo' &&
      _vm.$route.name != 'buyOrderRef' &&
      _vm.$route.name != 'buyOrderMsg' &&
      _vm.$route.name != 'buyUpdateMsg' &&
      _vm.$route.name != 'buyCoupons' &&
      _vm.$route.name != 'buyCouponsHs'
    )?_c('LayMain'):_c('LayBuy'),_c('HomeFoot')],1)}
var staticRenderFns = []

export { render, staticRenderFns }