export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'sonHome',
    name: "sonHomez",
    meta: {
        title: '子账号',
        layout: 'NoLayout',
        userType: 1,
    },
    children: [
        {
            path: 'sonHome',
            component: () => import('@/views/son/sonHome.vue'),
            name: "sonHome",
            meta: {
                title: '子账号管理',
                layout: 'LaySon',
                validation: true,
                userType: 1
            },
        },
        {
            path: 'sonAuth',
            component: () => import('@/views/son/sonAuth.vue'),
            name: "sonAuth",
            meta: {
                title: '授权',
                layout: 'NoLayout',
                validation: true,
                userType: 1,
                pageType: 1,
            },
        },

    ]
}