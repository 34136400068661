import {
    getAuth,
    getSAuth,
    getBAuth,
    getAAuth,
    getSchool
} from "@/utils/auth";
// import env from '@/settings/env.js';
import store from "@/store/index.js";

const MAIN_PATH = "mainLogin"
const SHOP_PATH = "shopLogin"
const BUY_PATH = "buyLogin"
const ADMIN_PATH = "/hxz"
export function initRouterGuard(router) {
    router.beforeEach((to, from, next) => {
        let text = store.state.title ? store.state.title : 'Shopee'
        document.title = to.meta.title ? to.meta.title + '_' + text : text
        store.state.chatShow = false
        // 是否开启加载动画
        if (!to.meta.pageType) {
            store.state.globalLoadShow = true
        }

        if (to.path == "/announcementInfo") {
            // 公告详情页设置面包屑标题
            to.meta.bread[2].name = JSON.parse(localStorage.ARTICLEINFO).title
        }

        // 存储用户类型 1.主账号，2.卖家账号，3.买家账号，4.管理后台账号
        // localStorage.USERTYPE = to.meta.userType
        // 判断页面是否需要验证
        // if (to.meta.validation) {
        // 主账号
        if (to.meta.userType == 1) {
            // if (getAuth()) {
            next();
            // } else {
            //     if (to.name == 'mainLogin') {
            //         next();
            //     } else {
            //         turnAround(next)
            //     }
            // }
        }
        if (to.meta.userType == 2) {
            // if (getSAuth()) {
            next();
            // } else {
            //     console.log("卖家未登陆")
            //     // if (to.path !== SHOP_PATH) {
            //     //     next(SHOP_PATH);
            //     // }
            //     turnAround(next)

            // }
        }

        if (to.meta.userType == 3) {
            // if (getBAuth()) {
            next();
            // } else {
            //     console.log("买家未登陆")
            //     // if (to.path !== BUY_PATH) {
            //     //     next(BUY_PATH);
            //     // }
            //     turnAround(next)
            // }
        }
        if (to.meta.userType == 4) {
            next()
            // //     console.log("管理后台未登陆")
            if (getSchool() == 'uu') {
                if (getAAuth()) {
                    next();
                } else {
                    if (to.name !== 'adminLogin') {
                        next(
                            {
                                path: '/adminLogin',
                                query: {
                                    is_admin: 1,
                                }
                            }
                        );
                    } else {
                        next();
                    }
                }
            } else {
                if (getAAuth()) {
                    next();
                } else {
                    // turnAround()
                }
            }

            //     if (getAAuth()) {
            //         next();
            //     } else {
            //         if (to.name !== 'adminLogin') {
            //             next(
            //                 {
            //                     path: '/adminLogin',
            //                     query: {
            //                         is_admin: 1,
            //                     }
            //                 }
            //             );
            //         } else {
            //             next();
            //         }
            //     }
            // } else {
            //     next();

            //     // console.log(getAAuth())
            //     // if (getAAuth()) {
            //     //     next();
            //     // } else {
            //     //     // turnAround()
            //     // }
            // }


        }

        // } else {
        //     next()
        // }
    });
    router.afterEach((to) => {
        if (!to.meta.pageType) {
            store.state.path = "/";
            store.state.index = "0";
        }
        setTimeout(function () {
            store.state.globalLoadShow = false
        }, 500)

    });
}


function turnAround(e) {
    // window.location.href = 'https://test.uutalent.net/'
    // e()
}
