export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'buyCart',
    name: "buyCartz",
    meta: {
        title: '购物车',
        layout: 'NoLayout',

    },
    children: [
        {
            path: 'buyCart',
            component: () => import('@/views/home/buyCart/buyCart.vue'),
            name: "buyCart",
            meta: {
                title: '购物车',
                layout: 'LayShopCart',

                userType: 3

            },
        },
        {
            path: 'buyPay',
            component: () => import('@/views/home/buyCart/buyPay.vue'),
            name: "buyPay",
            meta: {
                title: '结账',
                layout: 'LayShopCart',
                userType: 3
            },

        },
        {
            path: 'payOk',
            component: () => import('@/views/home/buyCart/payOk.vue'),
            name: "payOk",
            meta: {
                title: '支付成功',
                layout: 'LayShopCart',
                userType: 3
            },

        },

    ]
}