<template>
  <div>
    
    <MainHead> </MainHead>
    <LayMain
      v-if="
        $route.name != 'buyHome' &&
        $route.name != 'buyAddress' &&
        $route.name != 'buyEditPwd' &&
        $route.name != 'buyListing' &&
        $route.name != 'buyOrderInfo' &&
        $route.name != 'buyOrderRef' &&
        $route.name != 'buyOrderMsg' &&
        $route.name != 'buyUpdateMsg' &&
        $route.name != 'buyCoupons' &&
        $route.name != 'buyCouponsHs'
      "
    >
    </LayMain>
    <LayBuy v-else></LayBuy>
    <HomeFoot></HomeFoot>
  </div>
</template>
<script>
import LayMain from "./components/LayMain.vue";
import LayBuy from "./components/LayBuy";
import MainHead from "./components/head/MainHead.vue";
import HomeFoot from "./components/foot/HomeFoot";
export default {
  components: {
    LayMain,
    MainHead,
    HomeFoot,
    LayBuy,
  },
};
</script>