<template>
  <div class="layBuy">
    <div class="main-card" style="display: flex">
      <div class="left">
        <div class="tx">
          <div style="margin-right: 10px">
            <el-avatar :size="50" :src="$store.state.buyUserInfo.avatar
              ? $store.state.buyUserInfo.avatar
              : tx
              "></el-avatar>
          </div>
          <div>
            <div style="color: #000; margin-bottom: 6px">
              {{ $store.state.buyUserInfo.username }}
            </div>
            <div @click="toPush">
              <i class="el-icon-edit"></i>
              编辑个人资料
            </div>
          </div>
        </div>
        <div class="menu-list">
          <div v-for="(item, i) in menu" :key="i">
            <div style="
                font-size: 16px;
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                cursor: pointer;
              ">
              <i style="font-size: 20px" :class="item.icon" :style="{ color: item.iconC, 'margin-right': '10px ' }"></i>

              <span :class="$store.state.buyMenuIndex == item.value ? 'actives' : ''
                " @click="open(item)" class="name">
                {{ item.name }}</span>
            </div>
            <div class="lists" v-if="item.show">
              <div class="item" @click="qiehuan(val)" :class="$store.state.buyMenuIndex == val.value ? 'active' : ''"
                v-for="(val, index) in item.list" :key="index">
                {{ val.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { logout } from "@/api/buy.js";
import {
  removeAuth,
  removeSAuth,
  removeBAuth,
  removeAAuth
} from "@/utils/auth.js";
import tx from "@/assets/img/home/headImg.png";

export default {
  name: "LayBuy",
  data() {
    return {
      tx: tx,

      menu: [
        {
          name: "我的账户",
          value: 1,
          icon: "el-icon-user",
          iconC: "#2C66BB",
          show: true,
          list: [
            { name: "个人档案", value: 11, path: "buyHome" },
            { name: "地址", value: 12, path: "buyAddress" },
            { name: "更改密码", value: 13, path: "buyEditPwd" },
            { name: "退出登录", value: 14, path: false },
          ],
        },
        {
          name: "购买清单",
          icon: "el-icon-document",
          iconC: "#316AB6",
          show: false,
          value: 2,
          path: "buyListing",
        },
        // {
        //   name: "通知",
        //   value: 3,
        //   icon: "el-icon-bell",
        //   iconC: "#F46C51",
        //   show: true,
        //   list: [
        //     { name: "订单更新通知", value: 31, path: "buyOrderMsg" },
        //     { name: "Shopee更新", value: 32 , path: "buyUpdateMsg"  },
        //   ],
        // },
        {
          name: "我的优惠券",
          value: 4,
          icon: "el-icon-bell",
          iconC: "#F46C51",
          show: false,
          path: "buyCoupons",
        },
      ],
    };
  },
  created() {
    if (localStorage.BUTLOGINTYPE == 2) {
      this.menu[0].list.splice(2, 1);
    }
  },
  mounted() {
    if (
      this.$store.state.buyMenuIndex == 31 ||
      this.$store.state.buyMenuIndex == 32
    ) {
      this.menu[2].show = true;
    }
  },
  methods: {
    open(item) {
      if (item.list) {
        item.show = item.show ? false : true;
      } else {
        this.qiehuan(item);
      }
    },
    qiehuan(val) {
      this.$store.state.buyMenuIndex = val.value;
      if (val.path) {
        if (val.path != this.$route.name) {
          this.$router.push(val.path);
        }
      } else {
        this.$confirm("确定要退出登录吗?", "提示", {
          confirmButtonText: "退出",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          logout({
            school:localStorage.AUTH_SCHOOL
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: res.message,
              });
              sessionStorage.URL = res.data
              removeAuth();
              removeSAuth();
              removeBAuth();
              removeAAuth();
              this.$store.state.buyUserInfo = "";
              // location.reload();
              window.location.href = res.data
              // this.$router.replace("buyLogin");
            }
          });
        });
      }
    },
    toPush() {
      if (this.$route.name != "buyHome") {
        this.$router.push("buyHome");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.layBuy {
  background: #f5f5f5;

  .left {
    padding: 30px 0;
    width: 200px;

    .tx {
      display: flex;
      font-size: 14px;
      color: #9b9b9b;
      border-bottom: 1px solid #efefef;
      padding-bottom: 20px;
      align-items: center;
      cursor: pointer;
    }

    .menu-list {
      padding-top: 30px;
      color: rgba(0, 0, 0, 0.8);

      .name:hover {
        color: #ee4d2d;
      }

      .actives {
        color: #ee4d2d;
      }

      .lists {
        padding-left: 30px;

        .item {
          font-size: 14px;
          margin-bottom: 10px;
          color: rgba(0, 0, 0, 0.6);
          cursor: pointer;
        }

        .item:hover {
          color: #ee4d2d;
        }

        .active {
          color: #ee4d2d;
        }
      }
    }
  }

  .right {
    width: 1000px;
  }
}
</style>