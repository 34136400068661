import request from '@/api/request.js';

// 主账号注册
export function mainRegistered(data) {
    return request({
        url: 'admin/api/user/register',
        method: 'post',
        data: data
    });
}

export function register(data) {
    return request({
        url: 'admin/api/user/register',
        method: 'post',
        data: data
    });
}
// 主账号登录
export function mainLogin(data) {
    return request({
        url: 'admin/api/user/login',
        method: 'post',
        data: data
    });
}
// 主账号退出
export function logOut(data) {
    return request({
        url: 'admin/api/user/logout',
        method: 'post',
        data: data
    });
}
// 查看入驻记录
export function inList(data) {
    return request({
        url: 'admin/api/entry/list',
        method: 'get',
        params: data
    });
}
// 法人实名认证
export function legalAuth(data) {
    return request({
        url: 'admin/api/entry/legalAuth',
        method: 'post',
        data: data
    });
}
// 提交基本信息
export function baseInfo(data) {
    return request({
        url: 'admin/api/entry/baseInfo',
        method: 'post',
        data: data
    });
}
// 提交公司信息
export function companyInfo(data) {
    return request({
        url: 'admin/api/entry/companyInfo',
        method: 'post',
        data: data
    });
}

// 提交店铺信息
export function shopInfo(data) {
    return request({
        url: 'admin/api/entry/shopInfo',
        method: 'post',
        data: data
    });
}
// 忘记密码
export function forgotPassword(data) {
    return request({
        url: 'admin/api/user/forgotPassword',
        method: 'post',
        data: data
    });
}

// 我的商家和店铺
export function merchantList(data) {
    return request({
        url: 'admin/api/merchant/list',
        method: 'post',
        data: data
    });
}

// 主账号授权店铺

export function authorization(data) {
    return request({
        url: 'admin/api/shop/authorization',
        method: 'post',
        data: data
    });
}
// 解除绑定

export function unbind(data) {
    return request({
        url: 'admin/api/shop/unbind',
        method: 'post',
        data: data
    });
}
// 查找商家
export function findMerchant(data) {
    return request({
        url: 'admin/api/shop/findMerchant',
        method: 'post',
        data: data
    });
}

// 添加商家
export function addMerchant(data) {
    return request({
        url: 'admin/api/shop/addMerchant',
        method: 'post',
        data: data
    });
}

// 用户管理 - 5-卖家中心登入

export function sellerLogin(data) {
    return request({
        url: 'admin/api/user/sellerLogin',
        method: 'post',
        data: data
    });
}


//用户管理 - 7-获取虚拟主账号信息


export function getMainInfo(data) {
    return request({
        url: 'admin/api/user/getMainInfo',
        method: 'get',
        params: data
    });
}






