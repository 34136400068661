import { render, staticRenderFns } from "./LayShopCenter.vue?vue&type=template&id=754d4f70&scoped=true&"
import script from "./LayShopCenter.vue?vue&type=script&lang=js&"
export * from "./LayShopCenter.vue?vue&type=script&lang=js&"
import style0 from "./LayShopCenter.vue?vue&type=style&index=0&id=754d4f70&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754d4f70",
  null
  
)

export default component.exports