<template>
  <div>
    <SellHead></SellHead>
    <router-view></router-view>
  </div>
</template>
<script>
import SellHead from "./components/head/SellHead.vue";
export default {
  components: {
    SellHead,
  },
};
</script>