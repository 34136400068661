export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'inRecord',
    name: "inRecordz",
    meta: {
        title: 'Info Collect',
        layout: 'NoLayout',
        name: "入驻申请记录",
        userType: 1
    },
    children: [
        {
            path: 'inRecord',
            component: () => import('@/views/seller/in/inRecord.vue'),
            name: "inRecord",
            meta: {
                title: 'Info Collect',
                layout: 'LayRegistered',
                type: 3,
                name: "入驻申请记录",
                validation: true,
                userType: 1
            },
        },
        {
            path: 'inProcess',
            component: () => import('@/views/seller/in/inProcess.vue'),
            name: "inProcess",
            meta: {
                title: 'Info Collect',
                layout: 'LayRegistered',
                type: 3,
                name: "详情",
                validation: true,
                userType: 1
            },
        },
        {
            path: 'storeList',
            component: () => import('@/views/seller/in/storeList.vue'),
            name: "storeList",
            meta: {
                title: 'Info Collect',
                layout: 'LayRegistered',
                type: 3,
                name: "店铺列表",
                validation: true,
                userType: 1
            },
        },
        {
            path: 'inDetails',
            component: () => import('@/views/seller/in/inDetails.vue'),
            name: "inDetails",
            meta: {
                title: 'Info Collect',
                layout: 'LayRegistered',
                type: 4,
                name: "详情",
                validation: true,
                userType: 1
            },
        },

    ]
}