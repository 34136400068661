<template>
  <div class="liaoliao" v-if="$route.meta.chatShow">
    <div class="chat-window" v-if="$store.state.chatShow" :style="{ width: `${width}px` }">
      <div class="head">
        <div style="color: #ee4d2d; font-size: 18px; font-weight: bold">
          聊聊
        </div>
        <div class="left-btn">
          <div class="head-btn" @click="
              width = 630;
              type = 1;
            " v-if="type == 2" style="border-style: dashed">
            <i class="el-icon-back"></i>
          </div>
          <div class="head-btn" @click="
              width = 278;
              type = 2;
            " v-else style="border-style: dashed">
            <i class="el-icon-right"></i>
          </div>
          <div @click="close" class="head-btn" style="margin-left: 20px">
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
      <div class="content">
        <div v-if="httpShow" style="
            background: #fff;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
          <img style="width: 100px" :src="httpImg" alt="" />
        </div>
        <div class="content-left" v-show="type == 1">
          <div v-if="chatInfo" class="chat-mouth">
            <div class="chat-mouth_top">
              <div class="name">
                <span>{{ chatInfo.shop_name }}</span>
                <i style="margin: 0 10px -2px; font-size: 12px" class="el-icon-arrow-down"></i>
              </div>
            </div>
            <div class="rec"></div>
            <div class="inp">
              <div id="jia" style="
                  width: 332px;
                  overflow-wrap: anywhere;
                  visibility: hidden;
                  position: absolute;
                  height: auto;
                ">
                {{ liaosd }}
              </div>
              <textarea @keypress="pushKeyword" placeholder="发送讯息" id="textArea" v-model="liaosd"
                @input="getHight($event)" style="
                  border: none;
                  width: 100%;
                  padding: 10px 4px;
                  font-size: 16px;
                  resize: none;
                  line-height: 20px;
                  border-top: 1px solid #eee;
                  height: 42px;
                " maxlength="150" type="text" />
            </div>
            <div class="operation">
              <div style="
                  width: 90px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                ">
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  content="表情"
                  placement="top"
                >
                  <svg
                    t="1659523209217"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="15159"
                    width="22"
                    height="22"
                    style="cursor: pointer"
                  >
                    <path
                      d="M512 64C264.576 64 64 264.576 64 512s200.576 448 448 448 448-200.576 448-448S759.424 64 512 64z m0 832c-212.064 0-384-171.936-384-384s171.936-384 384-384 384 171.936 384 384-171.936 384-384 384z"
                      p-id="15160"
                      fill="#BBC8E3"
                    ></path>
                    <path
                      d="M256 384a64 64 0 1 0 128 0 64 64 0 1 0-128 0zM640 384a64 64 0 1 0 128 0 64 64 0 1 0-128 0zM224 512c0 159.072 128.928 288 288 288s288-128.928 288-288H224z"
                      p-id="15161"
                      fill="#BBC8E3"
                    ></path>
                  </svg>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="图片"
                  placement="top"
                >
                  <svg
                    t="1659523336796"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="17718"
                    width="21"
                    height="21"
                    style="cursor: pointer"
                  >
                    <path
                      d="M847.8 64.8H176.2c-61.8 0-111.9 50.1-111.9 111.8v670.8c0 61.8 50.1 111.8 111.9 111.8h671.5c61.8 0 111.9-50.1 111.9-111.8V176.6c0.1-61.7-50.1-111.8-111.8-111.8z m-658.6 71.1h645.4c29.7 0 53.8 24 53.8 53.7V640L751.2 454.8c-6.8-9.3-17.8-14.9-29.6-15.2-11.8-0.3-23.1 4.7-30.4 13.6L491.3 694.4l-128.9-117c-13.2-12-33.6-13.2-48.2-2.8L135.5 701.4V189.6c0-29.6 24-53.7 53.7-53.7zM834.7 888H189.2c-29.7 0-53.8-24-53.8-53.7v-44.1c2.2-1 4.4-2.2 6.4-3.7l191.8-136 135.7 123.2c7.7 7 18 10.5 28.5 9.8s20.2-5.6 26.8-13.5L719 535.5l154.7 209c3.9 5.3 9.1 9.2 14.8 11.8v78c0 29.6-24.1 53.7-53.8 53.7z"
                      p-id="17719"
                      fill="#BBC8E3"
                    ></path>
                    <path
                      d="M341 424c57.3 0 104-46.7 104-104s-46.7-104-104-104-104 46.7-104 104 46.7 104 104 104z m0-136c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.2-32 32-32z"
                      p-id="17720"
                      fill="#BBC8E3"
                    ></path>
                  </svg>
                </el-tooltip> -->

                <el-popover v-model="goodsShow" placement="top-start" width="340" trigger="click">
                  <div>
                    <div style="
                        border-bottom: 2px solid #ee4d2d;
                        padding: 4px;
                        display: inline-block;
                      ">
                      <div style="display: flex; align-items: center">
                        <el-image style="width: 14px; height: 14px; margin-right: 10px" :src="chatInfo.shop_avatar"
                          fit="fit"></el-image>
                        <span style="font-szie: 12px; color: #ee4d2d">{{
                          chatInfo.shop_name
                          }}</span>
                      </div>
                    </div>
                    <div style="
                        position: relative;
                        border-top: 1px solid #eee;
                        margin-bottom: 10px;
                      ">
                      <input v-model="keywords" style="
                          width: 80%;
                          background: #eee;
                          border: none;
                          padding-left: 30px;
                          line-height: 28px;
                          margin: 4px 0;
                        " type="text" />
                      <i class="el-icon-search" style="
                          position: absolute;
                          top: 14px;
                          left: 12px;
                          font-size: 14px;
                        "></i>
                      <el-button type="primary" size="mini" @click="goodsListShow(chatInfo.shop_id)">搜索</el-button>
                    </div>
                    <div v-if="goodsList && goodsList.length != 0" style="
                        padding-top: 10px;

                        max-height: 300px;
                        overflow: scroll;
                        overflow-x: hidden;
                        padding-right: 10px;
                      ">
                      <div v-for="item in goodsList" :key="item.id">
                        <div style="display: flex">
                          <el-image style="
                              width: 24px;
                              height: 24px;
                              margin-right: 10px;
                            " :src="item.image" fit="fit"></el-image>
                          <div style="font-size: 12px">
                            <div style="width: 200px; font-size: 14px" class="single-hidden">
                              {{ item.title }}
                            </div>
                            <div style="margin-top: 4px">{{ item.price }}</div>
                          </div>
                        </div>
                        <div style="
                            display: flex;
                            justify-content: right;
                            border-bottom: 1px solid #eee;
                            padding-bottom: 10px;
                          ">
                          <el-button type="primary" size="mini" @click="sendGoods(item)">发送</el-button>
                        </div>
                      </div>
                    </div>
                    <div v-else style="text-align: center">没有找到商品</div>
                  </div>
                  <!-- <el-tooltip
                    class="item"
                    effect="dark"
                    content="商品"
                    placement="top"
                    slot="reference"
                  >
                    <svg
                      t="1659523111369"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="14036"
                      width="22"
                      height="22"
                      style="cursor: pointer"
                    >
                      <path
                        d="M793.1 928H229.6c-35.4 0-69.6-15-93.6-41s-36.1-61.3-33.2-96.6l47.1-572c5.4-65.5 61-116.8 126.8-116.8h469.2c65.7 0 121.4 51.3 126.8 116.7l47.1 572c2.9 35.3-9.2 70.5-33.2 96.6-24 26.1-58.1 41.1-93.5 41.1zM276.7 191c-19.6 0-36.1 15.3-37.7 34.7l-47.1 572c-0.9 10.7 2.6 20.9 9.9 28.7 7.2 7.9 17.1 12.2 27.9 12.2h563.5c10.7 0 20.6-4.3 27.8-12.2 7.3-7.9 10.8-18.1 9.9-28.8l-47.1-572c-1.6-19.5-18.2-34.7-37.7-34.7H276.7z"
                        fill="#BBC8E3"
                        p-id="14037"
                      ></path>
                      <path
                        d="M511.9 534.7c-100.3 0-188.6-87.5-214.7-212.8-5-24.2 10.5-47.8 34.6-52.8 24.2-5.1 47.8 10.5 52.8 34.6 17.4 83.4 69.7 141.7 127.2 141.7 57.7 0 110.1-58.4 127.3-142.1 5-24.2 28.6-39.7 52.8-34.7 24.2 5 39.7 28.6 34.7 52.8-25.8 125.5-114.1 213.3-214.7 213.3z"
                        fill="#BBC8E3"
                        p-id="14038"
                      ></path>
                    </svg>
                  </el-tooltip> -->
                </el-popover>
              </div>
              <div style="display: flex; align-items: center">
                <svg @click="pushKeyword" t="1659522901152" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="5989" width="24" height="24" style="cursor: pointer">
                  <path
                    d="M929.962667 499.029333L108.074667 86.954667a15.701333 15.701333 0 0 0-22.314667 17.792l84.736 346.026666c1.28 5.248 5.12 9.472 10.24 11.093334l145.066667 49.834666-144.981334 49.834667a15.36 15.36 0 0 0-10.154666 11.093333l-84.906667 346.538667a15.786667 15.786667 0 0 0 22.314667 17.792l821.888-409.728a16.213333 16.213333 0 0 0 7.082666-6.954667 15.914667 15.914667 0 0 0-7.082666-21.248z m-747.306667 321.706667L232.106667 618.666667l290.005333-99.541334a7.893333 7.893333 0 0 0 0-14.933333L232.106667 404.821333 182.869333 203.605333l617.002667 309.376-617.216 307.712z"
                    p-id="5990" :fill="`${liaosd}` != '' ? '#ee4d2d' : '#b0b0b0'"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="welcome" v-else>
            <div>
              <img style="width: 200px" :src="img1" alt="" />
              <div style="padding-left: 10px">欢迎来到聊天</div>
            </div>
          </div>
        </div>
        <!-- 右侧消息列表 -->
        <div class="content-right">
          <div class="chat-search">
            <el-input placeholder="搜索名称" v-model="keyword" size="medium" class="input-with-select" @input="obMsgList">
              <el-select @change="obMsgList" style="width: 80px" v-model="select" slot="append">
                <el-option label="全部" value="0"></el-option>
                <el-option label="已读" value="1"></el-option>
                <el-option label="未读" value="2"></el-option>
              </el-select>
            </el-input>
          </div>

          <div v-if="msgList.list && msgList.list.length != 0" class="chat-list">
            <div @click="openChat(item)" :style="{
                background: chatInfo.m_id == item.m_id ? '#ebedf1' : '',
              }" class="chat-item" v-for="(item, i) in msgList.list" :key="i">
              <div>
                <el-image style="width: 40px; height: 40px; margin-right: 10px"
                  :src="item.shop_avatar ? item.shop_avatar : txImg" fit="fit"></el-image>
              </div>
              <div>
                <div style="font-weight: 600">{{ item.shop_name }}</div>
                <div style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 194px;
                    color: #b0b0b0;
                    height: 19px;
                  ">
                  <div class="single-hidden" style="width: 100px">
                    {{ item.message }}
                  </div>
                  <div>{{ formatTimeA(item.add_time * 1000, 5) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="wu" v-else>
            <div>
              <img style="width: 160px" :src="img2" alt="" />
              <div style="font-size: 14px; margin-left: -10px">
                找不到相关对话
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-btn" @click="openWindow" v-else>
      <img style="width: 30px; height: 30px; margin-right: 10px" :src="liaoImg" alt="" />
      聊聊
    </div>
  </div>
</template>
<script>
  import { getMsgList, addMsg, getChatList } from "@/api/chat.js";
  import txImg from "@/assets/img/home/tx.jpg";
  import {
    // goodsDetail,
    searchGoods,
  } from "@/api/home.js";
  import {

    removeAuth,
    removeSAuth,
    removeBAuth,
    removeAAuth
} from '@/utils/auth.js';
  export default {
    data() {
      return {
        liaoImg: require("@/assets/img/app/liaoliao.png"),
        img1: require("@/assets/img/app/liao.png"),
        img2: require("@/assets/img/app/liao-list.png"),
        httpImg: require("@/assets/img/app/http.gif"),
        lshow: false,
        type: 1,
        width: 630,
        select: "0",
        scrollHeight: "42px",
        liaosd: "",
        keyword: "",
        msgList: "",
        listPage: 1,
        listPageSize: 100,
        msgPage: 1,
        msgPageSize: 100,
        chatInfo: "", // 聊天对象信息
        goods_id: 0,
        order_id: 0,
        order_sn: "",
        msgInfo: { list: [] },
        heartbeat: null,
        httpShow: true,
        goodsList: [], //商品列表
        keywords: "",
        goodsShow: false, //商品列表显示隐藏
        txImg: txImg,
      };
    },
    mounted() {
      // this.goodsListShow();
    },
    beforeDestroy() {
      this.heartbeat = null;
    },
    methods: {
      // 获取消息列表
      obMsgList() {
        // localStorage.USERTYPE = 3;
        getMsgList({
          keyword: this.keyword,
          status: this.select,
          page: this.listPage,
          page_size: this.listPageSize,
        }).then((res) => {
          if (res.code == 1) {
            this.msgList = res.data;
            // let a=JSON.stringify(res.data.list[0])
            // this.msgList.list.push(JSON.parse(a))
            // this.msgList.list[1].m_id=54
            //      this.msgList.list[1].shop_id=19
            // 判断是否需要直接对话
            // if (this.select == "0") {
            let arr = [];
            let arr1 = [];
            this.msgList.list.forEach((item) => {
              arr.push(item.shop_id);
              arr1.push(item.m_id);
            });
            if (this.chatInfo) {
              if (arr.indexOf(this.chatInfo.shop_id) == -1) {
                this.msgList.list.push({
                  ...this.chatInfo,
                  message: "",
                  add_time: "",
                  m_id: 0,
                });
              } else {
                if (this.chatInfo.m_id) {
                  this.chatInfo =
                    this.msgList.list[arr1.indexOf(this.chatInfo.m_id)];
                } else {
                  this.chatInfo =
                    this.msgList.list[arr.indexOf(this.chatInfo.shop_id)];
                }
              }

              // if (this.chatInfo.m_id) {
              this.openChat(this.chatInfo);
              // }
            }
            this.httpShow = false;
            // }
          } else {
            removeAuth();
            removeSAuth();
            removeBAuth();
            removeAAuth();
            if (sessionStorage.BACKURL) {
              window.location.href = sessionStorage.BACKURL
            }
          }
          // if (res.code == -1) {
          //   this.$router.push("buyLogin");
          //   this.$store.state.buyLoginType = 2;
          // }
        });
      },
      // 打开窗口
      openWindow() {
        // 获取聊天对象
        if (sessionStorage.CHATINFO) {
          this.chatInfo = JSON.parse(sessionStorage.CHATINFO);
        }
        // localStorage.USERTYPE = 3;
        this.$store.state.chatShow = true;
        this.type = 1;
        this.width = 630;
        let _this = this;
        this.obMsgList();
        this.heartbeat = setInterval(function () {
          _this.obMsgList(_this);
        }, 30000);
      },
      // 关闭窗口
      close() {
        clearInterval(this.heartbeat);
        this.msgInfo.list = [];
        this.$store.state.chatShow = false;
        this.httpShow = true;
      },
      // 打开左侧聊天框
      openChat(item) {
        // localStorage.USERTYPE = 3;
        if (this.chatInfo && this.chatInfo.m_id != item.m_id) {
          let box = document.getElementsByClassName("rec");
          box[0].innerHTML = "";
        }

        // this.goodsListShow(item.shop_id);
        this.chatInfo = {
          shop_avatar: item.shop_avatar,
          shop_id: item.shop_id,
          shop_name: item.shop_name,
          m_id: item.m_id,
        };

        // this.chatInfo=item
        if (item.m_id == 0) {
          let box = document.getElementsByClassName("rec");
          box[0].innerHTML = "";
          this.msgInfo = {
            list: [],
          };
          return false;
        }
        if (item.m_id != 0 && item.m_id) {
          getChatList({
            m_id: item.m_id,
            page: this.msgPage,
            page_size: this.msgPageSize,
          }).then((res) => {
            if (res.code == 1) {
              let arr = [];
              this.msgInfo.list.forEach((val) => {
                arr.push(val.m_id);
              });
              res.data.list.reverse();
              res.data.list.forEach((item) => {
                item.time = this.formatTime(item.time);
                if (arr.indexOf(item.m_id) == -1) {
                  if (item.administrator == "right") {
                    if (item.goods) {
                      item.goods.time = item.time;
                      this.addRightGoods(item.goods);
                    } else {
                      this.addRightMsgBox(item);
                    }
                  }
                  if (item.administrator == "left") {
                    this.addLeftMsgBox(item);
                  }
                }
              });
              this.msgInfo = res.data;
            }
          });
        }
      },

      getHight() {
        let textArea = document.getElementById("textArea");
        // let jia = document.getElementById("jia");
        if (this.liaosd == "") {
          textArea.style.height = "42px";
        } else {
          if (textArea.scrollHeight > 42 && textArea.scrollHeight < 100) {
            textArea.style.height = textArea.scrollHeight + "px";
          }
        }
      },
      // 发送消息
      pushKeyword(e, type) {
        if (e.keyCode) {
          if (e.keyCode == 13) {
            e.preventDefault();
            if (this.liaosd != "") {
              // localStorage.USERTYPE = 3;
              addMsg({
                shop_id: this.chatInfo.shop_id,
                goods_id: this.goods_id,
                order_id: this.order_id,
                order_sn: this.order_sn,
                message: this.liaosd,
                type: 1,
              }).then((res) => {
                if (res.code == 1) {
                  // 创建消息元素
                  this.openChat(this.chatInfo);
                  // this.addRightMsgBox(this.liaosd);
                }
              });
            }
          }
        } else {
          if (this.liaosd != "" || type) {
            // localStorage.USERTYPE = 3;
            addMsg({
              shop_id: this.chatInfo.shop_id,
              goods_id: this.goods_id,
              order_id: this.order_id,
              order_sn: this.order_sn,
              message: this.liaosd,
              type: 1,
            }).then((res) => {
              if (res.code == 1) {
                this.goodsShow = false;
                // 创建消息元素
                this.openChat(this.chatInfo);
                // this.addRightMsgBox(this.liaosd);
              }
            });
          }
        }
      },
      // 打开获取商品列表
      goodsListShow(id) {
        this.goodsList = [];
        searchGoods({
          goods_status: "0,1",
          keyword: this.keywords,
          shop_id: id,
          category_id: 0,
          type: 0,
          order: 0,
          page: 1,
          page_size: 1000,
        }).then((res) => {
          if (res.code == 1) {
            this.goodsList = res.data.list;
            this.$forceUpdate();
            // window.scrollTo(0,0)
          }
        });
      },
      // 发送商品
      sendGoods(data) {
        // this.addRightGoods(data);
        this.goods_id = data.id;
        this.pushKeyword({ keyCode: false }, 1);
      },
      addRightGoods(data) {
        // 创建外层元素
        let box = document.getElementsByClassName("rec");
        let time = document.createElement("div");
        time.style["font-size"] = "12px";
        time.style.color = "#b0b0b0";
        time.style.margin = "10px 20px";
        time.style["text-align"] = "right";
        time.innerHTML = data.time;
        box[0].appendChild(time);
        let abc = document.createElement("div");
        abc.style.display = "flex";
        abc.style.alignItems = "center";
        abc.style.justifyContent = "right";
        abc.style["font-size"] = "12px";
        box[0].appendChild(abc);
        // 创建商品容器
        let boxSon = document.createElement("div");
        boxSon.style["margin-bottom"] = "12px";
        boxSon.style["width"] = "200px";
        // boxSon.style["text-align"] = " right";
        boxSon.style.background = "#D6E5FF";
        boxSon.style["margin-right"] = "20px";
        boxSon.style["border-radius"] = "4px";
        boxSon.style["padding"] = "4px";
        abc.appendChild(boxSon);

        let cbd = document.createElement("div");
        cbd.style["width"] = "100%";
        cbd.style.background = "#fff";
        cbd.style.height = "76px";
        cbd.style["padding"] = "8px";
        boxSon.appendChild(cbd);
        // 创建商品标题
        let text = document.createElement("div");
        text.style["margin-bottom"] = "4px";
        text.innerHTML = "商品";
        cbd.appendChild(text);
        // 创建商品内部容器
        let goodsBox = document.createElement("div");
        goodsBox.style.display = "flex";
        goodsBox.style["align-items"] = "center";
        // 创建商品图片
        let img = document.createElement("img");
        img.style.width = "40px";
        img.style.height = "40px";
        img.style["margin-right"] = "4px";
        img.src = data.image;
        goodsBox.appendChild(img);
        let name = document.createElement("div");
        let name1 = document.createElement("div");
        name1.style.width = "130px";
        name1.style.overflow = "hidden";
        name1.style["text-overflow"] = "ellipsis";
        name1.style["white-space"] = "nowrap";
        name1.innerHTML = data.title;
        name.appendChild(name1);
        let name2 = document.createElement("div");
        name2.style.color = "#ee4d2d";
        name2.style["margin-top"] = "6px";
        name2.innerHTML = data.price;
        name.appendChild(name2);
        goodsBox.appendChild(name);
        cbd.appendChild(goodsBox);
        box[0].scrollTop = box[0].scrollHeight;
      },
      // 添加右侧信息
      addRightMsgBox(data) {
        let box = document.getElementsByClassName("rec");
        let abc = document.createElement("div");
        abc.style.display = "flex";
        abc.style.alignItems = "center";
        abc.style.justifyContent = "right";
        box[0].appendChild(abc);
        let boxSon = document.createElement("div");
        boxSon.style["margin-bottom"] = "12px";
        boxSon.style["max-width"] = "200px";
        boxSon.style["text-align"] = " right";
        abc.appendChild(boxSon);
        let time = document.createElement("div");
        time.style["font-size"] = "12px";
        time.style.color = "#b0b0b0";
        time.style.margin = "10px 20px";
        time.style["text-align"] = "right";
        time.innerHTML = data.time;
        boxSon.appendChild(time);
        let cbd = document.createElement("div");
        cbd.style["width"] = "auto";
        cbd.style["border-radius"] = "4px";
        cbd.style.padding = "8px";
        cbd.style.background = "#D7E4FF";
        cbd.style["font-size"] = "14px";
        cbd.style.color = "#000";
        cbd.style.margin = "4px 20px";
        cbd.style.display = "inline-block";
        cbd.innerHTML = data.message;
        boxSon.appendChild(cbd);
        box[0].scrollTop = box[0].scrollHeight;
        this.liaosd = "";
      },
      // 添加左侧信息
      addLeftMsgBox(data) {
        let box = document.getElementsByClassName("rec");
        let abc = document.createElement("div");
        abc.style.display = "flex";
        abc.style.alignItems = "center";
        abc.style.justifyContent = "left";
        box[0].appendChild(abc);
        let boxSon = document.createElement("div");
        boxSon.style["margin-bottom"] = "12px";
        boxSon.style["max-width"] = "200px";
        boxSon.style["text-align"] = " left";
        abc.appendChild(boxSon);
        let time = document.createElement("div");
        time.style["font-size"] = "12px";
        time.style.color = "#b0b0b0";
        time.style.margin = "10px 20px";
        time.style["text-align"] = "left";
        time.innerHTML = data.time;
        boxSon.appendChild(time);
        let cbd = document.createElement("div");
        cbd.style["width"] = "auto";
        cbd.style["border-radius"] = "4px";
        cbd.style.padding = "8px";
        cbd.style.background = "#F5F6F8";
        cbd.style["font-size"] = "14px";
        cbd.style.color = "#000";
        cbd.style.margin = "2px 20px";
        cbd.style.display = "inline-block";
        cbd.innerHTML = data.message;
        boxSon.appendChild(cbd);
        box[0].scrollTop = box[0].scrollHeight;
      },
    },
  };
</script>
<style lang="less" scoped>
  .liaoliao {
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 999;
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.03);

    .chat-btn {
      width: 120px;
      background: #fff;
      // box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.03);
      padding: 10px 0;
      display: flex;
      align-content: center;
      justify-content: center;
      color: #ee4d2d;
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
      line-height: 30px;
      cursor: pointer;
    }

    .chat-window {
      width: 630px;
      height: 472px;
      background: #fff;
      border-radius: 4px;

      .head {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.09);

        .left-btn {
          display: flex;

          // align-items: center;
          .head-btn {
            width: 16px;
            height: 16px;
            border: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }

      .content {
        display: flex;
        margin-top: 2px;
        position: relative;

        .content-left {
          width: 358px;
          height: 436px;
          background: #fff;

          .welcome {
            width: 100%;
            height: 100%;
            margin-top: -40px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }

          .chat-mouth {
            height: 100%;
            display: flex;
            flex-direction: column;
            background: #fff;

            .chat-mouth_top {
              font-weight: 600;
              font-size: 14px;
              min-height: 56px;
              display: flex;
              align-items: center;
              padding: 0 20px;
              border-bottom: 1px solid #eee;

              .name {
                cursor: pointer;
              }
            }

            .rec {
              width: 100%;
              height: 100%;
              // background: red;
              overflow: scroll;
              overflow-x: hidden;
              overflow-wrap: anywhere;

              .res-a {
                color: red;
              }
            }

            .rec::-webkit-scrollbar {
              width: 4px;
              /*设置滚动条的宽度*/
            }

            .rec::-webkit-scrollbar-thumb {
              border-radius: 10px;
              /*设置滚动条的圆角*/
              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              /*设置内阴影*/
              background: #b0b0b0;
              /*设置滚动条的颜色*/
            }

            .inp {
              padding: 4px 10px;
            }

            .operation {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 4px 10px;
            }
          }
        }

        .content-right {
          width: 278px;
          height: 436px;
          border-left: 1px solid #eee;
          background: #fff;

          .wu {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-top: -40px;
          }

          .chat-search {
            padding: 10px;
            background: #fff;
          }

          .chat-list {
            height: 380px;
            overflow: scroll;

            .chat-item {
              height: 60px;
              display: flex;
              align-items: center;
              padding: 0 20px;
              font-size: 14px;
              cursor: pointer;
            }

            .chat-item:hover {
              background: #ebedf1;
            }
          }
        }
      }
    }

    #textArea::-webkit-scrollbar {
      width: 4px;
      /*设置滚动条的宽度*/
    }

    #textArea::-webkit-scrollbar-thumb {
      border-radius: 10px;
      /*设置滚动条的圆角*/
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      /*设置内阴影*/
      background: #b0b0b0;
      /*设置滚动条的颜色*/
    }
  }
</style>