import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title:'Shopee',
    baseInfo: {},
    globalLoadShow: false,
    path: "",      //卖家后台路径
    adminPath: "",  //管理后台路径
    index: "0",
    shop_info: 0,
    homeFootData: [],
    homeMainData: [],
    buyCartList: [],
    buyCartLength: 0,      //购物车商品数量
    searchValue: "",
    searchType: 1,//店铺主页搜索框选项---0:在此商店  1:在shopee
    search_module: [],  //搜索词
    banner_module: [],   //轮播图
    advert_module: [],    //广告位
    buyLoginType: 2,   //买家登录页类型---1:注册；2:登录
    buyUserInfo: "",  //买家用户信息
    buyMenuIndex: 11,   //买家中心选中项
    addressRadio: "",
    addressData: "",
    coupons: {
      title: "创建优惠券"
    },
    vipIndex: 1,       //管理后台vip类型下标
    chatShow: false,    //通讯
    centerInfo: '',    //卖家中心数据
    openMenu: [],
    wlIndex: 3,//物流下标
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
