<template>
  <span>
    <component :is="layout"></component>
    <div class="shopee-loading" v-if="$store.state.globalLoadShow">
      <img class="xzimg" :src="xz" alt="" />
      <img class="simg" :src="s" alt="" />
    </div>
    <Communication ref="chat"></Communication>
  </span>
</template>

<script>
  import LayHome from "@/layout/LayHome.vue";
  import NoLayout from "@/layout/NoLayout.vue";
  import LaySeller from "@/layout/LaySeller.vue";
  import LayRegistered from "@/layout/LayRegistered.vue";
  import LayShopOpen from "@/layout/LayShopOpen.vue";
  import LayShopCenter from "@/layout/LayShopCenter.vue";
  import LayShopCenter2 from "@/layout/LayShopCenter2.vue";
  import LayShopCart from "@/layout/LayShopCart.vue";

  import LayBuyLogin from "@/layout/LayBuyLogin.vue";

  import LayBuySignup from "@/layout/LayBuySignup.vue";
  import LaySon from "@/layout/LaySon.vue";
  import LayAdmin from "@/layout/LayAdmin.vue";

  import LayAdminLogin from "@/layout/LayAdminLogin.vue";
  import { setSchool } from "@/utils/auth.js";
  import { baseInfo } from "@/api/common.js";
  import { login } from "@/api/eco.js";

  export default {
    components: {
      LayHome,
      NoLayout,
      LaySeller,
      LayRegistered,
      LayShopOpen,
      LayShopCenter,
      LayShopCenter2,
      LayShopCart,
      LayBuyLogin,
      LayBuySignup,
      LaySon,
      LayAdmin,
      LayAdminLogin,
    },
    data() {
      return {
        xz: require("@/assets/img/app/xz.png"),
        s: require("@/assets/img/app/s.png"),
      };
    },
    created() {
      // document.title = "";

      // login({
      //   code: this.$route.query.code,
      // }).then((res) => {
      //   if (res.code == 1) {
      //     // 设置主，买，卖凭证
      //     setAuth(res.data.token);
      //     setSAuth(res.data.token);
      //     setBAuth(res.data.token);
      //     setSchool(res.data.school);
      let urlArr = window.location.host.split(".");
      setSchool(urlArr[0]);
      //     localStorage.USERINFO = res.data.account;
      //     // 设置管理后台凭证
      //     setAAuth(res.data.teacher_token);
      //     localStorage.ADMIN_USERNAME = res.data.username;
      // setSchool("v3test");
      // setSchool("v3test");
      this.getBaseInfo();
      //   }
      // });



      // if (urlArr.length == 3) {
      //   setSchool("uu");
      // } else {
      // alert(urlArr)



      // setSchool("fzdx-ym");
      // setSchool("zzgyxx"); 

      // }
      // 控制本地还是线上版本1-本地2-线上
      localStorage.ENVTYPE = 2;

      // 在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem("store")) {
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem("store"))
          )
        );
      }
      // 在页面刷新时将vuex里的信息保存到sessionStorage里
      // beforeunload事件在页面刷新时先触发
      window.addEventListener("beforeunload", (e) => {
        this.$store.state.chatShow = false;
        sessionStorage.setItem("store", JSON.stringify(this.$store.state));
      });
    },
    mounted() {

    },
    methods: {
      getBaseInfo() {
        baseInfo().then((res) => {
          if (res.code == 1) {
            this.$store.state.baseInfo = res.data;
            // 更改网站标题和图标
            if (this.$route.name == "fecebook") {
              document.title = "FaceBook";
            } else {
              this.$store.state.title = res.data.site_name

              let text = this.$store.state.title ? this.$store.state.title : 'Shopee'
              document.title = this.$route.meta.title ? this.$route.meta.title + '_' + text : text
              // document.title = res.data.site_name;
            }
            window.onload = function () {
              var link =
                document.querySelector("link[rel*='icon']") ||
                document.createElement("link");
              link.type = "image/x-icon";
              link.rel = "shortcut icon";
              link.href = res.data.favicon;
              document.getElementsByTagName("head")[0].appendChild(link);
            };
            sessionStorage.BACKURL = res.data.back_url
          }
          if (res.code == 6000) {
            this.$router.replace("error");
          }
        });
      },
    },
    computed: {
      layout() {
        return this.$route.meta.layout;
      },
    },
  };
</script>
<style lang="less">
  .app {
    height: 100%;

  }

  * {
    font-family: rob, hy;
    padding: 0;
    margin: 0;
    outline: none;
    box-sizing: border-box;
    // word-break: break-word;
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    /*设置滚动条的宽度*/
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /*设置滚动条的圆角*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    /*设置内阴影*/
    background: #b0b0b0;
    /*设置滚动条的颜色*/
  }

  html,
  body {
    height: 100%;
    touch-action: none;
  }

  .main-card {
    width: 1200px;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  ._2QcfeN:hover {
    color: hsla(0, 0%, 100%, 0.7);
  }

  ul,
  ol {
    // list-style: none;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .marginL {
    margin-left: 0.3125rem;
  }

  .marginL10 {
    margin-left: 10px;
  }

  .svg-icon {
    width: 100%;
    height: 100%;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .shopee-button {
    border: 1px solid #e5e5e5;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 32px;
    min-width: 72px;
    padding: 0 16px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .shopee-button--primary {
    color: #fff;
    background-color: #ee4d2d;
    border-color: #ee4d2d;
  }

  .shopee-red {
    color: #ee4d2d;
  }

  .el-message--error {
    background: #fff;
    box-shadow: 0 0 4px 0 #b0b0b0;
    border: none;
    color: #000;

    .el-message__content {
      color: #000;
    }
  }

  .el-input-group__append,
  .el-input-group__prepend {
    background: #fff;
  }

  .el-message--success {
    background: #fff;
    box-shadow: 0 0 4px 0 #b0b0b0;
    border: none;
    color: #000;

    .el-message__content {
      color: #000;
    }
  }

  .shopee-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    overflow: hidden;
    background: #fff;
    z-index: 9999999999;
  }

  .xzimg {
    width: 200px;
    height: 200px;
    animation: fadenum 2.5s infinite;
    animation-timing-function: linear;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  @keyframes fadenum {
    100% {
      transform: rotate(360deg);
    }
  }

  .simg {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .container-active .slide-verify-slider-mask-item[data-v-2921b679]:hover {
    background: #ee4d2d !important;
    border-color: #ee4d2d !important;
  }

  .slide-verify-slider-mask-item:hover {
    background: #ee4d2d !important;
  }

  .container-active .slide-verify-slider-mask {
    background: #ee4d2d !important;
    border-color: #ee4d2d !important;
  }

  .sms-check-code-wrapper .sms-code-bottom .sms-code-item .sms-line {
    height: 1px !important;
  }

  .caidai {
    display: flex;
    overflow: hidden;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
  }

  .caidai .red {
    width: 40px;
    height: 3px;
    background: #f4a5b0;
    margin-right: 10px;
  }

  .caidai .blue {
    width: 40px;
    height: 3px;
    background: #6fa6d6;
    margin-right: 10px;
  }

  .single-hidden {
    overflow: hidden; // 设置超出隐藏
    text-overflow: ellipsis; //设置溢出的标识是省略号
    white-space: nowrap; // 强制不换行
  }

  .row-hidden {
    overflow: hidden; // 溢出隐藏
    text-overflow: ellipsis; // 溢出用省略号显示
    display: -webkit-box; // 作为弹性伸缩盒子模型显示。
    -webkit-line-clamp: 2; // 显示的行数
    -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
  }

  .triangleT {
    width: 8px;
    height: 8px;
    border: 4px solid transparent;
    border-bottom: 4px solid #000;
    margin-bottom: 3px;
    cursor: pointer;
  }

  .triangleB {
    width: 8px;
    height: 8px;
    border: 4px solid transparent;
    border-top: 4px solid #000;
    cursor: pointer;
  }

  .triangleC {
    width: 8px;
    height: 8px;
    border: 4px solid transparent;
    border-bottom: 4px solid #ee4d2d;
    margin-bottom: 3px;
    cursor: pointer;
  }

  .triangleD {
    width: 8px;
    height: 8px;
    border: 4px solid transparent;
    border-top: 4px solid #ee4d2d;
    cursor: pointer;
  }

  // elUI边框颜色
  .el-table--border,
  .el-table--group {
    border: 1px solid #000 !important;
  }

  .el-submenu__title {
    font-size: 12px;
  }

  .el-menu-item {
    font-size: 12px;
  }
</style>