<template>
  <div class="homeFoot">
    <!-- <div class="h-line"></div> -->
    <div class="introduce" v-if="$route.name == 'home'">
      <div class="main-card" style="
          border-bottom: 1px solid rgba(0, 0, 0, 0.09);
          padding-bottom: 40px;
        ">
        <div>
          <section class="shopee-footer-section">
            <h2 class="shopee-footer-section__heading">
              SHOPEE MALAYSIA | AN ONLINE SHOPPING PLATFORM WITH GREAT DEALS
            </h2>
            <p class="shopee-footer-section__content">
              Shopee Malaysia is a leading online shopping site based in
              Malaysia that brings you great deals, with platforms existing
              across Asia including Singapore, Thailand, Indonesia, Vietnam,
              Philippines, and Taiwan. We offer unbeatable deals featuring an
              endless range of products priced at affordable rates. What’s even
              better? We enhance your experience with Shopee Guarantee, which
              means your payment is held in our system and only released to the
              seller after you have received your purchase in good condition!
              All because we value you as our customer. What are you waiting
              for? Come aboard and start browsing!
            </p>
          </section>
          <section class="shopee-footer-section">
            <h2 class="shopee-footer-section__heading">
              SHOPEE MALAYSIA -- QUALITY PRODUCTS, LOW PRICES
            </h2>
            <p class="shopee-footer-section__content">
              Shopee strives to provide you with a great online shopping
              experience and we know a great experience often includes
              affordable prices. That’s why you should check out Shopee Malaysia
              site/app’s daily online Shocking Sale, where we offer you
              different products at a discounted price, ensuring you get a bang
              for your buck. We even offer free shipping vouchers on products up
              to 5kg in West Malaysia, and 1kg in East Malaysia! For those who
              are brand-loyal, head over to
              <a href="javasrcipt:;">Shopee Mall</a> for authentic products at
              superb prices.
            </p>

            <p class="shopee-footer-section__content">
              Want to look your best without burning a hole in your wallet?
              Check out Style by Shopee for our top picks in fashion. Want to
              get the best prices for day-to-day items? Browse through our
              Lowest Price Guaranteed page. If you find the same Lowest Price
              Guaranteed item being sold at a lower price by a competitor, we
              will reward you with 120% of the price difference in Shopee Coins!
              You can also find official online shops for some of Malaysia’s
              biggest brands here, such as
              <a href="javasrcipt:;">Nestle</a>,
              <a href="javasrcipt:;">Unilever</a>,
              <a href="javasrcipt:;">Mydin</a>,
              <a href="javasrcipt:;">Tefal</a>,
              <a href="javasrcipt:;">Calvin Klein</a>, and more right here on
              our platform.
            </p>

            <p class="shopee-footer-section__content">
              Want the best deals for food and household items, and enjoy online
              grocery express delivery to your doorstep? Be sure to visit our
              <a href="javasrcipt:;">Shopee Mart</a>! Another quintessential
              aspect of every purchase is the reliability of your product. Most
              products listed on Shopee have reviews by previous buyers in
              Malaysia, allowing you to make informed purchases. Now you can be
              at ease when shopping with Shopee!
            </p>
          </section>
          <section class="shopee-footer-section">
            <h2 class="shopee-footer-section__heading">
              SHOP FOR VARIETY WITH SHOPEE
            </h2>
            <p class="shopee-footer-section__content">
              Everyone loves online shopping for the convenience and the low
              prices are a bonus! Shopee Malaysia strives to help you get a bang
              for your buck with multiple sales and promotions happening at any
              one time. Make sure your wallet is ready for our big sales
              campaigns, such as
              <a href="javasrcipt:;">7.7 Orange Madness</a>,
              <a href="javasrcipt:;">9.9 Super Shopping Day</a>,
              <a href="javasrcipt:;">10.10 Brands Festival</a>,
              <a href="javasrcipt:;">11.11 Big Sale</a>, and
              <a href="javasrcipt:;">12.12 Birthday Sale</a>. We also have
              massive sales and offer great savings to you during major
              festivals like
              <a href="javasrcipt:;">Chinese New Year</a>
              and <a href="javasrcipt:;">Raya</a>! At the same time, check in
              often because we also have smaller sale periods which are
              category-specific, such as our
              <a href="javasrcipt:;">Baby Fair</a> and
              <a href="javasrcipt:;">Black Friday sales</a>.
            </p>

            <p class="shopee-footer-section__content">
              Let’s not forget about the frequently occurring
              <a href="javasrcipt:;">Super Brand Day</a>! On these days,
              specific brands offer amazing deals for their top-selling items,
              so be sure to keep an eye out to see if your favourite brand is
              featuring. Of course, there are always different kinds of smaller
              promotions happening every day, so you really will benefit if you
              constantly check your Shopee app or the website to ensure you are
              not missing any mind-blowing offers! Want even more savings? Then
              apply for the
              <a href="javasrcipt:;">Maybank Shopee Credit Card</a>
              so you can earn Shopee coins when spending on Shopee purchases,
              dining, entertainment, and contactless transactions. More Shopee
              coins mean more discounts during your shopping sprees on our
              platform!
            </p>
          </section>
          <section class="shopee-footer-section">
            <h2 class="shopee-footer-section__heading">
              GET FREE SHIPPING ON SHOPEE
            </h2>
            <p class="shopee-footer-section__content">
              Enjoy Free Shipping on Shopee! For buyers, shop to your heart’s
              content and enjoy lower prices for your purchases. If you buy
              enough from the same seller, you’ll get your items delivered for
              free! For sellers, get your shipping fees reimbursed - selling
              can’t get any easier on Shopee Malaysia.
            </p>
          </section>
        </div>
      </div>
    </div>
    <div class="between" v-if="$route.name == 'home'">
      <div style="
          width: 1200px;
          margin: 0 auto;
          font-size: 14px;
          margin-bottom: 10px;
          color: rgba(0, 0, 0, 0.54);
          padding-left: 6px;
        ">
        分类
      </div>
      <!-- <div v-for="item in $store.state.homeFootData" :key="item.id">
        {{ item.name }}
        <div v-for="val in item.list" :key="val.id">{{ val.name }}</div>
      </div> -->
      <div class="fenlei" v-if="$store.state.homeFootData.length != 0">
        <div class="item" v-for="(item, i) in data" :key="i">
          <div v-for="(val, i) in item.index" :key="i" @click="toPush(val, 1)">
            <div class="title">{{ $store.state.homeFootData[val].name }}</div>
            <div class="name">
              <div v-for="(vals, index) in $store.state.homeFootData[val].list" :key="index"
                @click.stop="toPush(vals, 2, index)">
                {{ vals.name
                }}<span style="margin: 0 2px; color: rgba(0, 0, 0, 0.54)"
                  v-if="$store.state.homeFootData[val].list.length - 1 != index">|</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="PjIOU" v-if="$route.name == 'home'">
      <div style="
          width: 1200px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        ">
        <div class="IjKibG">
          <div style="margin-right: 60px">
            <div class="title">物流</div>
            <div style="width: 190px; display: flex; flex-wrap: wrap">
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                ">
                <div class="footer-my-background shopee_express" style="width: 55px; height: 10px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                ">
                <div class="footer-my-background jnt" style="width: 55px; height: 17px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                ">
                <div class="footer-my-background pos_laju" style="width: 55px; height: 23px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                ">
                <div class="footer-my-background dhl" style="width: 55px; height: 20px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                ">
                <div class="footer-my-background ninja_van" style="width: 55px; height: 13px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                ">
                <div class="footer-my-background city_link" style="width: 55px; height: 15px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                ">
                <div class="footer-my-background the_lorry" style="width: 55px; height: 20px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                ">
                <div class="footer-my-background abx_express" style="width: 55px; height: 27px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                ">
                <div class="footer-my-background best_express" style="width: 55px; height: 23px"></div>
              </div>
            </div>
          </div>
          <div style="margin-right: 60px">
            <div class="title">PAYMENT</div>
            <div style="width: 190px; display: flex; flex-wrap: wrap">
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <div class="footer-my-background visa" style="width: 55px; height: 18px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <div class="footer-my-background mastercard" style="width: 55px; height: 29px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <div class="footer-my-background fpx" style="width: 55px; height: 24px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <div class="footer-my-background cimb_bank" style="width: 55px; height: 19px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <div class="footer-my-background maybank" style="width: 55px; height: 22px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <div class="footer-my-background asd_new" style="width: 55px; height: 24px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <div class="footer-my-background american-express" style="width: 39px; height: 39px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <div class="footer-my-background jcb" style="width: 55px; height: 30px"></div>
              </div>
              <div style="
                  width: 55px;
                  height: 29px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <div class="footer-my-background kkmart" style="width: 55px; height: 55px"></div>
              </div>
            </div>
          </div>
          <div>
            <div class="title">关注我们</div>
            <div>
              <ul class="SOb2n7">
                <li class="o8Gbgv">
                  <a href="javasrcipt:;" class="FA0WjS" title="">
                    <div class="footer-my-background footer-my-f-png _6ZyW8Y"></div>
                    Facebook
                  </a>
                </li>
                <li class="o8Gbgv">
                  <a href="javasrcipt:;" class="FA0WjS" title="">
                    <div class="footer-my-background footer-my-ins-png _6ZyW8Y"></div>
                    Instagram
                  </a>
                </li>
                <li class="o8Gbgv">
                  <a href="javasrcipt:;" class="FA0WjS" title="">
                    <div class="
                        footer-my-background
                        footer-my-shopee_mamak-png
                        _6ZyW8Y
                      "></div>
                    Shopee Mamak
                  </a>
                </li>
                <li class="o8Gbgv">
                  <a href="javasrcipt:;" class="FA0WjS" title="">
                    <div class="footer-my-background footer-my-lin-png _6ZyW8Y"></div>
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div style="
          width: 1200px;
          margin: 0 auto;
          display: flex;
          align-items: center;
        ">
        <a href="record.beian_url " style="color: rgba(0, 0, 0, 0.54)"></a>
        <img :src="baImg" style="vertical-align: top; margin: 2px 5px 0 5px" /><a href="record.security_addr"
          style="color: rgba(0, 0, 0, 0.54)"></a>
        <span style="margin-left: 5px">CopyRight © {{ $store.state.baseInfo.copyright }}</span>
      </div>
    </div>

  </div>
</template>
<script>
// import env from "@/settings/env.js";
import { allCategory } from "@/api/common.js";
export default {
  name: "HomeFoot",
  data() {
    return {
      data: [
        {
          index: [0, 5, 11, 12],
        },
        {
          index: [1, 6, 13, 14, 15],
        },
        {
          index: [2, 8, 16, 17, 23],
        },
        {
          index: [3, 7, 18, 19, 20],
        },
        {
          index: [9, 21, 22],
        },
      ],
      log: require("@/assets/img/app/shangb.png"),
      baImg: require("@/assets/img/app/beian.png"),
      name: "",
    };
  },
  created() {
    this.allFootCategory();
    this.name = this.$route.name;
  },
  methods: {
    toPush(value, type, index) {
      let cindex = "";
      if (type == 1) {
        sessionStorage.CATEGORYID =
          this.$store.state.homeFootData[value].category_id;
      }
      if (type == 2) {
        sessionStorage.CATEGORYID = value.pid;
        cindex = index;
      }
      window.scrollTo(0, 0);
      this.$router.push({ path: "goodsSearchs", query: { index: cindex } });
    },
    allFootCategory() {
      allCategory().then((res) => {
        if (res.code == 1) {
          this.$store.state.homeFootData = res.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homeFoot {
  .bottom {
    margin: 0 auto;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    line-height: 50px;
    background: #fbfbfb;
  }

  .introduce {
    width: 100%;
    background: #fff;
    // margin-top: 30px;
    padding-top: 30px;
    border-top: 4px solid #ee4d2d;

    .shopee-footer-section__heading {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
      margin-top: 30px;
    }

    .shopee-footer-section__content {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);

      a {
        color: rgba(0, 0, 0, 0.54);
        text-decoration: underline;
      }
    }

    .shopee-footer-section__content a:hover {
      color: #ee4d2d;
    }
  }

  .between {
    padding-top: 40px;

    .fenlei {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 20%;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.54);
        padding: 6px;

        .title {
          cursor: pointer;
        }

        .name {
          margin-bottom: 10px;
          display: flex;
          flex-wrap: wrap;
          line-height: 20px;
          margin-top: 6px;
          cursor: pointer;
        }

        .title:hover {
          color: #ee4d2d;
        }

        .name div:hover {
          color: #ee4d2d;
        }
      }
    }
  }

  .PjIOU {
    background: #fbfbfb;
    border-top: 4px solid #ee4d2d;
    padding: 30px;
    padding-bottom: 0;
  }

  .IjKibG {
    display: flex;

    .title {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
      margin-bottom: 30px;
    }

    .footer-my-background {
      background-image: url("../../../assets/img/app/shangb.png");
    }

    .visa {
      background-size: 1080% 1911.111111111111%;
      background-position: 41.1873840445269% 82.20858895705521%;
    }

    .mastercard {
      background-size: 1080% 1186.2068965517242%;
      background-position: 97.03153988868274% 3.1746031746031744%;
    }

    .fpx {
      background-size: 1080% 1433.3333333333333%;
      background-position: 1.8552875695732838% 31.875%;
    }

    .cimb_bank {
      background-size: 1080% 1810.5263157894738%;
      background-position: 1.8552875695732838% 19.384615384615383%;
    }

    .maybank {
      background-size: 836.6197183098592% 1186.2068965517242%;
      background-position: 100% 18.73015873015873%;
    }

    .asd_new {
      background-size: 792% 1042.4242424242425%;
      background-position: 1.9267822736030829% 3.215434083601286%;
    }

    .american-express {
      background-size: 792% 458.6666666666667%;
      background-position: 20.23121387283237% 21.189591078066915%;
    }

    .jcb {
      background-size: 270% 203.55029585798817%;
      background-position: 99.46524064171123% 69.14285714285714%;
    }

    .kkmart {
      background-size: 309.375% 179.16666666666666%;
      background-position: 2.487562189054726% 100%;
    }

    .shopee_express {
      background-size: 535.1351351351351% 1911.111111111111%;
      background-position: 45.962732919254655% 54.60122699386503%;
    }

    .jnt {
      background-size: 1080% 2023.5294117647059%;
      background-position: 73.46938775510205% 3.058103975535168%;
    }

    .pos_laju {
      background-size: 860.8695652173913% 1186.2068965517242%;
      background-position: 42.285714285714285% 40.95238095238095%;
    }

    .dhl {
      background-size: 1080% 1720%;
      background-position: 59.36920222634508% 25%;
    }

    .ninja_van {
      background-size: 848.5714285714286% 1810.5263157894738%;
      background-position: 75.38167938931298% 19.076923076923077%;
    }

    .city_link {
      background-size: 594% 1228.5714285714287%;
      background-position: 40.48582995951417% 25.632911392405063%;
    }

    .the_lorry {
      background-size: 848.5714285714286% 1075%;
      background-position: 52.29007633587786% 69.23076923076923%;
    }

    .abx_express {
      background-size: 1080% 1274.0740740740741%;
      background-position: 21.335807050092765% 3.1545741324921135%;
    }

    .best_express {
      background-size: 479.03225806451616% 674.5098039215686%;
      background-position: 42.5531914893617% 3.4129692832764507%;
    }

    .the_lorry {
      background-size: 848.5714285714286% 1075%;
      background-position: 52.29007633587786% 69.23076923076923%;
    }

    .SOb2n7 {
      text-decoration: none;
      display: block;
      color: rgba(0, 0, 0, 0.54);
      list-style-type: none;
      margin: 0 0 1.5625rem;
      padding: 0;

      .o8Gbgv {
        font-size: 0.75rem;
        margin-bottom: 0.75rem;
        text-transform: capitalize;

        .FA0WjS {
          display: flex;
        }

        .FA0WjS:hover {
          color: #ee4d2d;
        }
      }

      .FA0WjS {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.54);

        ._6ZyW8Y {
          height: 1rem;
          width: 1rem;
          margin-bottom: 0.25rem;
          margin-right: 0.625rem;
        }

        .footer-my-f-png {
          background-size: 1856.25% 1075%;
          background-position: 61.20996441281139% 3.2051282051282053%;
        }

        .footer-my-ins-png {
          background-size: 1856.25% 1075%;
          background-position: 56.93950177935943% 38.782051282051285%;
        }

        .footer-my-shopee_mamak-png {
          background-size: 1856.25% 1075%;
          background-position: 39.50177935943061% 69.23076923076923%;
        }

        .footer-my-lin-png {
          background-size: 1856.25% 1075%;
          background-position: 83.80782918149467% 3.2051282051282053%;
        }
      }
    }
  }
}
</style>