<template>
  <div>
    <div
      style="height: auto"
      v-if="this.$route.meta.type == 1 || this.$route.meta.type == 3"
    >
      <SellHead :type="this.$route.meta.type"></SellHead>
      <router-view></router-view>
      <RefFoot></RefFoot>
    </div>
    <div
      style="height: auto; position: relative"
      v-if="this.$route.meta.type == 2"
    >
      <SellHead2></SellHead2>
      <router-view></router-view>
      <RefFoot2></RefFoot2>
      <div style="height: 56px; width: 100%"></div>
    </div>
        <div style="height: auto" v-if="this.$route.meta.type == 4">
      <SellHead :type="this.$route.meta.type"></SellHead>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import SellHead from "./components/head/SellHead";
import SellHead2 from "./components/head/SellHead2";
import RefFoot from "./components/foot/RegFoot";
import RefFoot2 from "./components/foot/RegFoot2";
export default {
  data() {
    return {};
  },
  components: {
    SellHead,
    SellHead2,
    RefFoot,
    RefFoot2,
  },
  mounted() {

  },
};
</script>