<template>
  <div class="admin-menu" :style="{ height: '100%' - '56px' }">
    <el-menu
      :default-active="$store.state.adminPath"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      @select="handeSelect"
      style="border: none"
    >
      <!-- <el-menu-item index="admin">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item>

      <el-submenu index="adminStore">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>店铺管理</span>
        </template>

        <el-menu-item index="adminStore">
          <span slot="title">店铺列表</span>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="adminGoods">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>商品管理</span>
        </template>
        <el-menu-item index="adminGoods">
          <span slot="title">商品列表</span>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="adminOrderList">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>订单管理</span>
        </template>

        <el-menu-item index="adminOrderList">
          <span slot="title">订单列表</span>
        </el-menu-item>
        <el-menu-item index="refundOrder">
          <span slot="title">退款订单</span>
        </el-menu-item>
        <el-menu-item index="returnGoodsOrder">
          <span slot="title">退货订单</span>
        </el-menu-item>
        <el-menu-item index="evaluationList">
          <span slot="title">评价管理</span>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="adminCoupons">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>营销管理</span>
        </template>

        <el-menu-item index="adminCoupons">
          <span slot="title">优惠券</span>
        </el-menu-item>
        <el-menu-item index="adminDiscount">
          <span slot="title">折扣活动</span>
        </el-menu-item>
        <el-menu-item index="adminSuit">
          <span slot="title">套装优惠</span>
        </el-menu-item>
        <el-menu-item index="adminLimit">
          <span slot="title">限时选购</span>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="studentsVip">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>会员管理</span>
        </template>

        <el-menu-item index="studentsVip">
          <span slot="title">学生会员</span>
        </el-menu-item>
        <el-menu-item index="buyersVip">
          <span slot="title">买家会员</span>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="userList">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>用户管理</span>
        </template>

        <el-menu-item index="userList">
          <span slot="title">用户列表</span>
        </el-menu-item>
        <el-menu-item index="roleManagement">
          <span slot="title">角色管理</span>
        </el-menu-item>
        <el-menu-item index="accessMenu">
          <span slot="title">权限菜单</span>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="campus">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>学校管理</span>
        </template>

        <el-menu-item index="campus">
          <span slot="title">学校管理</span>
        </el-menu-item>
        <el-menu-item index="teachers">
          <span slot="title">教师管理</span>
        </el-menu-item>
        <el-menu-item index="classNo">
          <span slot="title">班级管理</span>
        </el-menu-item>
      </el-submenu> -->
      <div v-for="(item, i) in arr" :key="i">
        <el-menu-item :index="item.action_name" v-if="item.children == 0">
          <i class="el-icon-s-home"></i>
          <!-- <img
            src="https://uu-shopee.oss-cn-hangzhou.aliyuncs.com/shopee/test/20220902/116620835011901.png"
            style="width: 20px; height: 20px; background: red;margin:0 10px"
          /> -->
          <span slot="title">{{ item.node_name }}</span>
        </el-menu-item>

        <el-submenu :index="item.action_name" v-else>
          <template slot="title">
            <i class="el-icon-menu"></i>
            <!-- <img
            src="https://uu-shopee.oss-cn-hangzhou.aliyuncs.com/shopee/test/20220902/116620835011901.png"
            style="width: 20px; height: 20px; background: red;margin:0 10px"
          /> -->
            <span>{{ item.node_name }}</span>
          </template>

          <el-menu-item
            v-for="(val, index) in item.children"
            :key="index"
            :index="val.action_name"
          >
            <span slot="title">{{ val.node_name }}</span>
          </el-menu-item>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>
<script>
import { homeData } from "@/api/admin.js";
// import { dataTool } from 'echarts';
export default {
  data() {
    return {
      arr: [],
    };
  },
  created() {
    this.getMenu();
  },
  methods: {
    getMenu() {
      homeData().then((res) => {
        if (res.code == 1) {
          this.arr = res.data.menu;
        }
      });
    },
    handleOpen() {},
    handleClose() {},
    handeSelect(value) {
      let path = this.$route.path;
      this.$store.state.adminPath = value;
      if (value == "studentsVip" || value == "buyersVip") {
        this.$store.state.vipIndex = value == "studentsVip" ? 1 : 2;

        if ("/studentsVip" != path) {
          this.$router.push("studentsVip");
        } else {
          this.$parent.$children[2].$children[0].handleClick(
            this.$store.state.vipIndex
          );
        }
        return false;
      }
      if ("/" + value != path) {
        this.$router.push(value);
      }
      // this.value = value;
    },
  },
};
</script>
<style lang="less" scoped>
.admin-menu {
  width: 224px;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}
</style>