<template>
  <div class="layShopCenter">
    <ShopCenterHead :centerInfo="centerInfo"></ShopCenterHead>
    <div v-if="httpShow">
      <div style="position: relative; height: 100%" v-if="show">
        <ShopMenu></ShopMenu>
        <div style="position: relative; width: 100%; bottom: 0">
          <div class="container">
            <router-view></router-view>
          </div>
        </div>
      </div>
      <div class="unauthorized" v-if="!show">
        <div style="color: #9a9a9a">
          当前商家下没有已授权的店铺，请
          <span style="color: #2996e8; cursor: pointer" @click="toAuth">点击此处</span>
          去授权
        </div>
      </div>
    </div>
    <div v-else style="text-align: center; line-height: 500px">
      <img :src="lodingImg" alt="" />
    </div>
  </div>
</template>
<script>
  import { shopCenterInfo } from "@/api/shop.js";
  import ShopCenterHead from "./components/head/ShopCenterHead";
  import ShopMenu from "./components/menu/ShopMenu";
  import { getAuth } from "@/utils/auth.js";
  export default {
    name: "layShopCenter",
    components: {
      ShopCenterHead,
      ShopMenu,
    },
    data() {
      return {
        show: false,
        httpShow: false,
        lodingImg: require("@/assets/img/app/http.gif"),
        centerInfo: {
          shop_info: {
            name: "",
            shop_name: "",
          },
        },
      };
    },
    computed: {
      path() {
        return this.$store.state.path;
      },
    },
    created() {

      if (this.$route.query.is_auth) {
        let obj = JSON.parse(this.$route.query.info)
        this.centerInfo.shop_info = obj
        this.httpShow = true;
        this.show = false;

      } else {
        this.getInfo();
      }

    },
    mounted() {
      // this.getInfo();
    },
    watch: {
      path: function (ov, nv) {
        if (this.path == "/") {
          this.getInfo();
        }
      },
    },
    methods: {
      getInfo() {
        shopCenterInfo().then((res) => {
          this.httpShow = true;
          if (res.code == 5000) {
            this.show = false;
            localStorage.CENTERINFO = JSON.stringify(res.data);
            this.$store.state.shop_info = res.data;
            this.centerInfo = res.data;
          }
          if (res.code == 1) {
            this.show = true;
            localStorage.CENTERINFO = JSON.stringify(res.data);
            this.$store.state.shop_info = res.data;
            this.centerInfo = res.data;
          }
          if (res.code == -1 || res.code == 6000) {
            if (sessionStorage.BACKURL) {
              window.location.replace(sessionStorage.BACKURL)
            }
          }
        });
      },
      toAuth() {
        // if (
        //   getAuth() &&
        //   JSON.parse(localStorage.CENTERINFO).constructor == Object &&
        //   JSON.parse(localStorage.CENTERINFO).shop_info.admin_id ==
        //     localStorage.MAINID
        // ) {
        this.$router.push({ path: "sonHome", query: { type: "AUTH" } });
        // }
        //  else {
        //   this.$router.push({ path: "mainLogin", query: { type: "AUTH" } });
        // }
      },
    },
  };
</script>
<style lang="less" scoped>
  .layShopCenter {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f6f6f6;
    overflow: scroll;

    .unauthorized {
      width: 1300px;
      height: 660;
      background: #fff;
      margin: 0 auto;
      text-align: center;
      line-height: 660px;
      margin-top: 60px;
    }

    .container {
      padding: 20px 20px 0 20px;
      position: absolute;
      left: 224px;
      right: 0;
      min-width: 1000px;
    }
  }
</style>