import request from '@/api/request.js';

// 系统基本配置
export function baseInfo(data) {
    return request({
        url: 'api/config/getBaseInfo',
        method: 'post',
        data: data,
    });
}
// 图片上传
export function uploadFiles(data) {
    return request({
        url: 'api/upload/files',
        method: 'post',
        data: data,
        contentType: false,
        processData: false,
    });
}
// 删除上传的图片
export function deleteFile(data) {
    return request({
        url: 'api/upload/deleteFile',
        method: 'post',
        data: data,
    });
}
// 视频上传
export function uploadFilesVideo(data,onUploadProgress) {
    return request({
        url: 'api/upload/video',
        method: 'post',
        data: data,
        onUploadProgress
    });
}
// 删除上传的视频
export function deleteVideo(data) {
    return request({
        url: 'api/upload/deleteVideo',
        method: 'post',
        data: data,
    });
}

// 入驻基本配置
export function configList(data) {
    return request({
        url: 'admin/api/config/list',
        method: 'get',
        params: data
    });
}
// 获取主品类
export function getMainCategory(data) {
    return request({
        url: 'admin/api/category/getMainCategory',
        method: 'get',
        params: data
    });
}
// 获取验证码
export function getSmsCode(data) {
    return request({
        url: 'api/sms/code',
        method: 'post',
        data: data
    });
}
// 获取物流商列表卖家
export function logisticsList(data) {
    return request({
        url: 'business/api/logistics/list',
        method: 'get',
        params: data
    });
}
// 获取物流商列表买家
export function logisticsLists(data) {
    return request({
        url: 'buyers/api/logistics/list',
        method: 'get',
        params: data
    });
}
// 获取商品分类
export function categoryInfo(data) {
    return request({
        url: 'business/api/category/index',
        method: 'post',
        data: data
    });
}
// 获取品牌列表
export function brandList(data) {
    return request({
        url: 'api/brand/index',
        method: 'post',
        data: data
    });
}
// 获取品牌列表-商店
export function brandLists(data) {
    return request({
        url: 'business/api/brand/lst',
        method: 'post',
        data: data
    });
}
// 获取底部所有分类
export function allCategory(data) {
    return request({
        url: 'buyers/api/index/category',
        method: 'get',
        params: data,
    });
}
// 获取首页一级分类
export function allMainCategory(data) {
    return request({
        url: 'buyers/api/category/allMainCategory',
        method: 'get',
        params: data,
    });
}

// 判断用户账号、手机号、邮箱是否已注册
export function findUserInfo(data) {
    return request({
        url: 'api/user/findUserInfo',
        method: 'post',
        data: data,
    });
}
// 查找账号
export function findAccount(data) {
    return request({
        url: 'api/user/findAccount',
        method: 'post',
        data: data,
    });
}