export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'myGoods',
    name: "myGoodsz",
    meta: {
        title: '商品',
        layout: 'NoLayout',
        userType: 2,
        name: "商品",
        pageType: 1,
    },
    children: [
        {
            path: 'myGoods',
            component: () => import('@/views/seller/shop/goods/myGoods.vue'),
            name: "myGoods",
            meta: {
                title: '我的商品',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的商品", path: false },
                ]
            },
        },
        {
            path: 'addGoods',
            component: () => import('@/views/seller/shop/goods/addGoods.vue'),
            name: "addGoods",
            meta: {
                title: '商品分类',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "", path: "myGoods" },
                    { name: "商品分类", path: false },
                ]
            },
        },
        {
            path: 'addGoodsInfo',
            component: () => import('@/views/seller/shop/goods/addGoodsInfo.vue'),
            name: "addGoodsInfo",
            meta: {
                title: '新增商品',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "", path: "myGoods" },
                    { name: "新增商品", path: false },
                ]
            },
        },
        {
            path: 'editGoodsInfo',
            component: () => import('@/views/seller/shop/goods/editGoodsInfo.vue'),
            name: "editGoodsInfo",
            meta: {
                title: '新增商品',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "", path: "myGoods" },
                    { name: "编辑商品", path: false },
                ]
            },
        },
        {
            path: 'fecebook',
            component: () => import('@/views/seller/shop/goods/fecebook.vue'),
            name: "fecebook",
            meta: {
                title: 'fecebook',
                layout: 'NoLayout',
                userType: 2,
                validation: true,
            },
        }
        ,

        {
            path: 'globalGoods',
            component: () => import('@/views/seller/shop/goods/globalGoods.vue'),
            name: "globalGoods",
            meta: {
                title: '全球商品',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "全球商品", path: false },
                ]
            },
        },
        {
            path: 'sendGlobalGoods',
            component: () => import('@/views/seller/shop/goods/sendGlobalGoods.vue'),
            name: "sendGlobalGoods",
            meta: {
                title: '选择店铺',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "全球商品", path: "globalGoods" },
                    { name: "选择店铺", path: false },
                ]
            },
        }
    ]
}