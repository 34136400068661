export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'shopCenter',
    name: "shopCenterz",
    meta: {
        title: '卖家中心',
        layout: 'NoLayout',
        userType: 2,
        name: "首页"
    },
    children: [
        {
            path: 'shopCenter',
            component: () => import('@/views/seller/shop/shopCenter.vue'),
            name: "shopCenter",
            meta: {
                title: '卖家中心',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true
            },
        },
        {
            path: 'announcement',
            component: () => import('@/views/seller/shop/announcement.vue'),
            name: "announcement",
            meta: {
                title: '公告',
                layout: 'LayShopCenter2',
                userType: 2,
                name: "公告",
                pageType: 1,
                validation: true,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "公告", path: "announcement" },
                ]
            },
        },
        {
            path: 'announcementInfo',
            component: () => import('@/views/seller/shop/announcementInfo.vue'),
            name: "announcementInfo",
            meta: {
                title: '卖家中心',
                layout: 'LayShopCenter2',
                userType: 2,
                name: "公告详情",
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "公告", path: "announcement" },
                    { name: "公告详情", path: false }
                ]
            },
        },
        {
            path: 'chatRoom',
            component: () => import('@/views/seller/shop/chatRoom/chatRoom.vue'),
            name: "chatRoom",
            meta: {
                title: '聊天室',
                layout: 'NoLayout',
                userType: 2,
                name: "聊天室",
                validation: true,
                pageType: 1,
            },
        },




    ]
}