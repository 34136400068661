<template>
  <div class="footer" style="">
    <p class="footer__footprint">© 2022 优优汇联。版权所有</p>
    <div class="language-selector footer-language-selector">
      <i class="footer-language-selector__icon shopee-icon"
        ><svg
          version="1.1"
          id="图层_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1024 1024"
          style="enable-background: new 0 0 1024 1024"
          xml:space="preserve"
        >
          <g>
            <path
              d="M362.1,255.5c39,4.5,80,7.4,122.4,8.2V103.2C436.5,119,392.6,174.9,362.1,255.5z"
            ></path>
            <path
              d="M717.8,247.6c44.8-7.4,86.3-17.1,123.6-28.6c-59.2-66.5-138.3-115-228-136.2C656.4,118.3,692.3,175.9,717.8,247.6z"
            ></path>
            <path
              d="M410.6,82.9c-89.6,21.1-168.7,69.6-228,136.2c37.3,11.5,78.8,21.2,123.6,28.6C331.7,175.9,367.6,118.3,410.6,82.9z"
            ></path>
            <path
              d="M539.6,103.2v160.5c42.4-0.9,83.3-3.7,122.4-8.2C631.4,174.9,587.5,119,539.6,103.2z"
            ></path>
            <path
              d="M484.4,920.8V760.3c-42.4,0.9-83.3,3.7-122.4,8.2C392.6,849.1,436.5,905,484.4,920.8z"
            ></path>
            <path
              d="M539.6,760.3v160.5c48-15.7,91.9-71.7,122.4-152.3C622.9,764,581.9,761.1,539.6,760.3z"
            ></path>
            <path
              d="M306.2,776.4c-44.8,7.4-86.3,17.1-123.6,28.6c59.2,66.5,138.3,115,228,136.2C367.6,905.7,331.7,848.1,306.2,776.4z"
            ></path>
            <path
              d="M613.4,941.1c89.6-21.1,168.7-69.6,228-136.2c-37.3-11.5-78.8-21.2-123.6-28.6C692.3,848.1,656.4,905.7,613.4,941.1z"
            ></path>
            <path
              d="M759.6,484.6H952c-5-80.9-31.7-155.9-74.5-219.2c-43.1,14.7-91.3,26.7-143.3,35.6C748.8,356.9,757.6,419.1,759.6,484.6z"
            ></path>
            <path
              d="M734.2,723c52,8.9,100.2,20.9,143.3,35.6c42.7-63.2,69.5-138.1,74.5-218.8H759.6C757.6,605.2,748.7,667.2,734.2,723z"
            ></path>
            <path
              d="M679.2,309.1c-44.6,5.5-91.3,8.8-139.6,9.8v165.8h164.9C702.4,420.9,693.4,361.4,679.2,309.1z"
            ></path>
            <path
              d="M704.4,539.7H539.6v165.4c48.3,0.9,95.1,4.3,139.6,9.8C693.4,662.7,702.4,603.3,704.4,539.7z"
            ></path>
            <path
              d="M319.6,484.6h164.9V318.9c-48.3-0.9-95.1-4.3-139.6-9.8C330.6,361.4,321.6,420.9,319.6,484.6z"
            ></path>
            <path
              d="M344.8,714.9c44.6-5.5,91.3-8.8,139.6-9.8V539.7H319.6C321.6,603.3,330.6,662.7,344.8,714.9z"
            ></path>
            <path
              d="M289.8,301c-52-8.9-100.2-20.9-143.3-35.6C103.7,328.7,77,403.7,72,484.6h192.4C266.4,419.1,275.2,356.9,289.8,301z"
            ></path>
            <path
              d="M264.4,539.7H72c5,80.8,31.8,155.6,74.5,218.8c43.1-14.7,91.3-26.7,143.3-35.6C275.3,667.2,266.4,605.2,264.4,539.7z"
            ></path>
          </g></svg
      ></i>
      <div class="shopee-dropdown">
        <span class="footer-language-selector__language" @click="openPop"
          >{{ data[index].lang }})<i class="shopee-icon"
            ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 768">
              <path
                d="M64 133.1c0-10.8 2.6-21.6 7.6-31.2 11.7-23 35.4-37.5 61.2-37.3h758.4c37.9 0 68.7 30.7 68.8 68.6 0 14.4-4.5 28.5-13 40.2l-379.2 525c-22.2 30.8-65.2 37.7-96 15.3-5.9-4.3-11.1-9.4-15.3-15.3L77.1 173.2C68.6 161.5 64 147.4 64 133.1z"
              ></path></svg></i
        ></span>
        <div
          class="shopee-popper"
          v-show="show"
          style="
            position: absolute;
            z-index: 10032;
            will-change: top, left;
            transform-origin: left bottom;
            bottom:30px
            left: 10px;
          "
        >
          <ul class="shopee-dropdown-menu">
            <li
              class="
                footer-language-selector__dropdown-item
                shopee-dropdown-item
              "
              v-for="(item, i) in data"
              :key="item.id"
              @click="selected(i)"
            >
              <!---->
              <span>{{ item.lang }}</span>
              <i
                class="footer-language-selector__checked-icon shopee-icon"
                v-show="i == index"
                ><svg
                  data-name="图层 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    d="M415.95 736a31.55 31.55 0 0 1-22.6-9.4l-192-192a32 32 0 0 1 45.3-45.3l169.3 169.4 361.4-361.4a32 32 0 0 1 45.3 45.3l-384 384a32 32 0 0 1-22.7 9.4z"
                    data-name="Layer 2"
                  ></path></svg
              ></i>
              <!---->
              <!---->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import lang from "@/i18n/lang/languages.js";
export default {
  data() {
    return {
      data: "",
      show: false,
      index: 0,
    };
  },
  created() {

    this.$i18n.locale;
    this.data = lang;
    this.data.forEach((item, i) => {
      if (this.$i18n.locale == item.type) {
        this.index = i;
      }
    });
  },
  methods: {
    openPop() {
      this.show = this.show ? false : true;
    },
    selected(index) {
      this.index = index;
      this.$i18n.locale = this.data[index].type;
      this.show = false;
    },
  },
};
</script>