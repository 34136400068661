import { render, staticRenderFns } from "./SellHead.vue?vue&type=template&id=388faf65&scoped=true&"
import script from "./SellHead.vue?vue&type=script&lang=js&"
export * from "./SellHead.vue?vue&type=script&lang=js&"
import style0 from "./SellHead.vue?vue&type=style&index=0&id=388faf65&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388faf65",
  null
  
)

export default component.exports