module.exports = {
    message: {
        title: "成功"
    },
    homeNavbar: {
        sellerCentre: '卖家中心',
        immediatelyIn: '立即入驻',
        notifications: '通知',
        help: '帮助中心',
        signUp: '注册',
        login: '登入',
        searchLanguage: "搜寻商品，品牌以及商店",
        searchLanguageStore: "在商店內搜索",
        searchLanguageShop:"在此商店"
    },
    mainAccountApply: {
        mainAccountApply: "申请主帐户",
        setBasicInfo: "设定基本消息",
        setLoginInfo: "设定登入信息",
        verify: "验证",
        complete: "完成",
        phoneNumber: "电话号码",
        phoneNumberMsg: "验证码将发送到电话号码以进行验证。"


    }
}