<template>
  <div class="layShopCenter">
    <ShopCenterHead :centerInfo="centerInfo"></ShopCenterHead>
    <!-- <div style="position: relative; height: 100%" v-if="show">
      <ShopMenu></ShopMenu>
      <div style="position: relative; width: 100%; bottom: 0">
        <div class="container">
          <router-view></router-view>
        </div>
      </div>
    </div> -->
    <router-view></router-view>
  </div>
</template>
<script>
import { shopCenterInfo } from "@/api/shop.js";
import ShopCenterHead from "./components/head/ShopCenterHead";
export default {
  name: "layShopCenter",
  components: {
    ShopCenterHead,
  },
  data() {
    return {
      show: false,
      centerInfo: {
        shop_info: {
          name: "",
          shop_name: "",
        },
      },
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {
    // this.getInfo();
  },
  methods: {
    getInfo() {
      shopCenterInfo().then((res) => {
        if (res.code == 5000) {
          this.show = false;
          localStorage.CENTERINFO = JSON.stringify(res.data);
          this.$store.state.shop_info = res.data;
          this.centerInfo = res.data;
        }
        if (res.code == 1) {
          this.show = true;
          localStorage.CENTERINFO = JSON.stringify(res.data);
          this.$store.state.shop_info = res.data;
          this.centerInfo = res.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.layShopCenter {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  overflow: scroll;
  .unauthorized {
    width: 1300px;
    height: 660;
    background: #fff;
    margin: 0 auto;
    text-align: center;
    line-height: 660px;
    margin-top: 60px;
  }
  .container {
    padding: 30px 48px 0 48px;
    position: absolute;
    left: 274px;
    right: 0;
    min-width: 1000px;
  }
}
</style>