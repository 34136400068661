export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'admin',
    name: "admins",
    meta: {
        title: '',
        layout: 'NoLayout',
        chatShow: true,
        userType: 4,
    },
    children: [
        {
            path: 'adminLogin',
            component: () => import('@/views/admin/login/adminLogin.vue'),
            name: "adminLogin",
            meta: {
                layout: 'LayAdminLogin',
                userType: 4,
                title: "管理后台登录"
            }
        },
        {
            path: 'admin',
            component: () => import('@/views/admin/index/index.vue'),
            name: "admin",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "首页",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "统计概览", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminStore',
            component: () => import('@/views/admin/store/storeList.vue'),
            name: "adminStore",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "店铺管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "店铺管理", path: false },
                    { name: "店铺列表", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminStoreInfo',
            component: () => import('@/views/admin/store/storeInfo.vue'),
            name: "adminStoreInfo",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "店铺管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "店铺管理", path: "adminStore" },
                    { name: "店铺列表", path: "adminStore" },
                    { name: "店铺详情", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminGoods',
            component: () => import('@/views/admin/goods/goodsList.vue'),
            name: "adminGoods",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "商品管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "商品管理", path: false },
                    { name: "商品列表", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminGoodsInfo',
            component: () => import('@/views/admin/goods/goodsInfo.vue'),
            name: "adminGoodsInfo",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "商品管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "商品管理", path: "adminGoods" },
                    { name: "详情", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminCategory',
            component: () => import('@/views/admin/goods/category.vue'),
            name: "adminCategory",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "分类管理",
                bread: [
                    { name: "首页", path: "admin" },
                    // { name: "商品管理", path: "adminGoods" },
                    { name: "分类管理", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminAddCategory',
            component: () => import('@/views/admin/goods/addCategory.vue'),
            name: "adminAddCategory",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "分类管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "分类管理", path: "adminCategory" },
                    { name: "新增分类", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminOrderList',
            component: () => import('@/views/admin/order/orderList.vue'),
            name: "adminOrderList",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "订单管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "订单管理", path: false },
                    { name: "交易订单", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminOrderInfo',
            component: () => import('@/views/admin/order/orderInfo.vue'),
            name: "adminOrderInfo",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "订单详情",
                bread: [
                    { name: "首页", path: "adminOrderList" },
                    { name: "订单管理", path: false },
                    { name: "详情", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminRefOrderInfo',
            component: () => import('@/views/admin/order/refOrderInfo.vue'),
            name: "adminRefOrderInfo",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "订单详情",
                bread: [
                    { name: "首页", path: "adminOrderList" },
                    { name: "退款订单", path: false },
                    { name: "详情", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'refundOrder',
            component: () => import('@/views/admin/order/refundOrder.vue'),
            name: "refundOrder",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "订单管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "订单管理", path: "adminOrderList" },
                    { name: "退款订单", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'returnGoodsOrder',
            component: () => import('@/views/admin/order/returnGoodsOrder.vue'),
            name: "returnGoodsOrder",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "订单管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "订单管理", path: "adminOrderList" },
                    { name: "退货订单", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'evaluationList',
            component: () => import('@/views/admin/order/evaluationList.vue'),
            name: "evaluationList",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "订单管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "订单管理", path: "adminOrderList" },
                    { name: "评价管理", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminCoupons',
            component: () => import('@/views/admin/marketing/coupons.vue'),
            name: "adminCoupons",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "优惠券管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "营销管理", path: false },
                    { name: "优惠券", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminCouponsInfo',
            component: () => import('@/views/admin/marketing/couponsInfo.vue'),
            name: "adminCouponsInfo",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "优惠券详情",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "营销管理", path: "adminCoupons" },
                    { name: "优惠券", path: "adminCoupons" },
                    { name: "详情", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminDiscount',
            component: () => import('@/views/admin/marketing/discount.vue'),
            name: "adminDiscount",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "折扣活动",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "营销管理", path: "adminCoupons" },
                    { name: "折扣活动", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminDiscountInfo',
            component: () => import('@/views/admin/marketing/discountInfo.vue'),
            name: "adminDiscountInfo",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "折扣详情",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "营销管理", path: "adminCoupons" },
                    { name: "折扣活动", path: "adminDiscount" },
                    { name: "详情", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminSuit',
            component: () => import('@/views/admin/marketing/suit.vue'),
            name: "adminSuit",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "套装优惠",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "营销管理", path: "adminCoupons" },
                    { name: "套装优惠", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminSuitInfo',
            component: () => import('@/views/admin/marketing/suitInfo.vue'),
            name: "adminSuitInfo",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "套装优惠详情",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "营销管理", path: "adminCoupons" },
                    { name: "套装优惠", path: "adminSuit" },
                    { name: "详情", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminLimit',
            component: () => import('@/views/admin/marketing/limit.vue'),
            name: "adminLimit",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "限时选购",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "营销管理", path: "adminCoupons" },
                    { name: "限时选购", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'adminLimitInfo',
            component: () => import('@/views/admin/marketing/limitInfo.vue'),
            name: "adminLimitInfo",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "限时选购详情",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "营销管理", path: "adminCoupons" },
                    { name: "限时选购", path: "adminLimit" },
                    { name: "详情", path: false },
                ],
                pageType: true,
                validation: true
            }
        },

        {
            path: 'studentsVip',
            component: () => import('@/views/admin/vip/studentsVip.vue'),
            name: "studentsVip",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "学生会员",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "会员管理", path: false },
                    { name: "会员", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'userList',
            component: () => import('@/views/admin/user/userList.vue'),
            name: "userList",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "用户管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "用户管理", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'editUser',
            component: () => import('@/views/admin/user/editUser.vue'),
            name: "editUser",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "用户管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "用户管理", path: "userList" },
                    { name: "用户列表", path: "userList" },
                    { name: "编辑", path: false },
                ],
                pageType: true,
                validation: true
            }
        },
        {
            path: 'roleManagement',
            component: () => import('@/views/admin/user/roleManagement.vue'),
            name: "roleManagement",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "角色管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "用户管理", path: 'userList' },
                    { name: "角色管理", path: false },
                ], pageType: true,
                validation: true
            }
        },

        {
            path: 'addRole',
            component: () => import('@/views/admin/user/addRole.vue'),
            name: "addRole",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "创建角色",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "用户管理", path: 'userList' },
                    { name: "角色管理", path: 'roleManagement' },
                    { name: "编辑", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'accessMenu',
            component: () => import('@/views/admin/user/accessMenu.vue'),
            name: "accessMenu",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "权限菜单",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "用户管理", path: 'userList' },
                    { name: "权限菜单", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'addAccess',
            component: () => import('@/views/admin/user/addAccess.vue'),
            name: "addAccess",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "创建角色",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "用户管理", path: 'userList' },
                    { name: "权限菜单", path: 'accessMenu' },
                    { name: "创建", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'campus',
            component: () => import('@/views/admin/school/campus.vue'),
            name: "campus",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "学校管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "学校管理", path: false },
                    { name: "学校列表", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'teachers',
            component: () => import('@/views/admin/school/teachers.vue'),
            name: "teachers",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "教师管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "学校管理", path: "campus" },
                    { name: "教师管理", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'teacherPoint',
            component: () => import('@/views/admin/school/teacherPoint.vue'),
            name: "teacherPoint",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "教师评分",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "学校管理", path: "campus" },
                    { name: "教师评分", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'classNo',
            component: () => import('@/views/admin/school/classNo.vue'),
            name: "classNo",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "班级管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "学校管理", path: "campus" },
                    { name: "班级管理", path: false },
                ], pageType: true,
                validation: true
            }
        }, {
            path: 'scoreBank',
            component: () => import('@/views/admin/school/scoreBank.vue'),
            name: "scoreBank",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "实训成绩",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "学校管理", path: "campus" },
                    { name: "实训成绩", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'adminNotice',
            component: () => import('@/views/admin/basis/noticeList.vue'),
            name: "adminNotice",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "公告管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "公告管理", path: false },
                    { name: "公告列表", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'adminAddNotice',
            component: () => import('@/views/admin/basis/addNotice.vue'),
            name: "adminAddNotice",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "公告管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "公告管理", path: "adminNotice" },
                    { name: "新增公告", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'adminBanner',
            component: () => import('@/views/admin/basis/banner.vue'),
            name: "adminBanner",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "轮播管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "轮播管理", path: false },
                    { name: "轮播列表", path: false },
                ], pageType: true,
                validation: true
            }
        },

        {
            path: 'adminAddBanner',
            component: () => import('@/views/admin/basis/addBanner.vue'),
            name: "adminAddBanner",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "轮播管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "轮播管理", path: "adminBanner" },
                    { name: "新增轮播", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'adminAdvert',
            component: () => import('@/views/admin/basis/advert.vue'),
            name: "adminAdvert",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "广告管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "广告管理", path: false },
                    { name: "广告列表", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'adminAddAdvert',
            component: () => import('@/views/admin/basis/addAdvert.vue'),
            name: "adminAddAdvert",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "广告管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "广告管理", path: "adminAdvert" },
                    { name: "新增广告", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'adminSearch',
            component: () => import('@/views/admin/basis/searchList.vue'),
            name: "adminSearch",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "搜素词管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "搜索词管理", path: false },
                    { name: "搜索词列表", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'adminSet',
            component: () => import('@/views/admin/system/systemSet.vue'),
            name: "adminSet",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "广告管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "设置", path: false },
                    { name: "系统设置", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'commentSchoolList',
            component: () => import('@/views/admin/review/score.vue'),
            name: "commentSchoolList",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "评分管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "评测中心", path: false },
                    { name: "评分管理", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'scoreManage',
            component: () => import('@/views/admin/review/scoreManage.vue'),
            name: "scoreManage",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "实训成绩",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "评测中心", path: false },
                    { name: "实训成绩", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'scoreBasis',
            component: () => import('@/views/admin/review/scoreBasis.vue'),
            name: "scoreBasis",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "基础组件管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "评测中心", path: false },
                    { name: "基础组件管理", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'scoreItem',
            component: () => import('@/views/admin/review/scoreItem.vue'),
            name: "scoreItem",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "评分组件管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "评测中心", path: false },
                    { name: "评分组件管理", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'operatingItem',
            component: () => import('@/views/admin/review/operatingItem.vue'),
            name: "operatingItem",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "运营组件管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "评测中心", path: false },
                    { name: "运营组件管理", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'operatingInfo',
            component: () => import('@/views/admin/review/operatingInfo.vue'),
            name: "operatingInfo",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "运营分详情",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "评测中心", path: false },
                    { name: "运营分详情", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'teacherMode',
            component: () => import('@/views/admin/review/teacherMode.vue'),
            name: "teacherMode",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "教师模块管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "评测中心", path: false },
                    { name: "教师模块管理", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'addressList',
            component: () => import('@/views/admin/robot/address.vue'),
            name: "addressList",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "地址管理",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "AI智能管理", path: false },
                    { name: "地址管理", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'buyRobot',
            component: () => import('@/views/admin/robot/buyRobot.vue'),
            name: "buyRobot",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "买家机器人",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "AI智能管理", path: false },
                    { name: "买家机器人", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'phrase',
            component: () => import('@/views/admin/robot/phrase.vue'),
            name: "phrase",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "买家常用语",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "AI智能管理", path: false },
                    { name: "买家常用语", path: false },
                ], pageType: true,
                validation: true
            }
        },
        {
            path: 'evaluation',
            component: () => import('@/views/admin/robot/evaluation.vue'),
            name: "evaluation",
            meta: {
                layout: 'LayAdmin',
                userType: 4,
                title: "买家评语",
                bread: [
                    { name: "首页", path: "admin" },
                    { name: "AI智能管理", path: false },
                    { name: "买家评语", path: false },
                ], pageType: true,
                validation: true
            }
        },

    ]
}