import { render, staticRenderFns } from "./HomeFoot.vue?vue&type=template&id=0352390b&scoped=true&"
import script from "./HomeFoot.vue?vue&type=script&lang=js&"
export * from "./HomeFoot.vue?vue&type=script&lang=js&"
import style0 from "./HomeFoot.vue?vue&type=style&index=0&id=0352390b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0352390b",
  null
  
)

export default component.exports