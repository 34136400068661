module.exports = {
    message: {
        title: "successful"
    },
    homeNavbar: {
        sellerCentre: 'Seller Centre',
        immediatelyIn: "ImmediatelyIn",
        notifications: 'Notifications',
        help: 'Help',
        signUp: 'Sign Up',
        login: 'Login',
        searchLanguage: "Shopee Premium: Anti-Aging & Suncare Essentials",
        searchLanguageStore: "Search in this shop",
        searchLanguageShop:"In This Shop"
    },
    mainAccountApply: {
        mainAccountApply: "Main-account Apply",
        setBasicInfo: "Set Basic Info",
        setLoginInfo: "Set Login Info",
        verify: "Verify",
        complete: "Complete"

    }
}