const AUTH_KEY = 'SHOPEE_MAIN_TOKEN';    //主账号
const AUTH_SHOP_KEY = "SHOPEE_SHOP_TOKEN";    //卖家账号
const AUTH_BUY_KEY = "SHOPEE_BUY_TOKEN";   //买家账号
const AUTH_SCHOOL = "AUTH_SCHOOL";     //学校标识
const AUTH_ADMIN_KEY = "SHOPEE_ADMIN_TOKEN"   //管理后台账号
const _s = window.localStorage
let _auth = '';
let _auths = "";
let _authb = "";
let _autha = "";
let school = ""
// 主账号
export function setAuth(auth) {
  _s.setItem(AUTH_KEY, auth);
  _auth = auth;
}

export function getAuth() {
  if (!_auth) {
    _auth = _s.getItem(AUTH_KEY);
  }
  return _auth;
}
export function removeAuth() {
  _s.removeItem(AUTH_KEY);
  _auth = '';
}

// 卖家账号
export function setSAuth(auth) {
  _s.setItem(AUTH_SHOP_KEY, auth);
  _auths = auth;
}
export function getSAuth() {
  if (!_auths) {
    _auths = _s.getItem(AUTH_SHOP_KEY);
  }
  return _auths;
}
export function removeSAuth() {
  _s.removeItem(AUTH_SHOP_KEY);
  _auths = '';
}

// 买家账号
export function setBAuth(auth) {
  _s.setItem(AUTH_BUY_KEY, auth);
  _authb = auth;
}
export function getBAuth() {
  if (!_authb) {
    _authb = _s.getItem(AUTH_BUY_KEY);
  }
  return _authb;
}
export function removeBAuth() {
  _s.removeItem(AUTH_BUY_KEY);
  _authb = '';
}

//管理后台账号
export function setAAuth(auth) {
  _s.setItem(AUTH_ADMIN_KEY, auth);
  _autha = auth;
}

export function getAAuth() {
  if (!_autha) {
    _autha = _s.getItem(AUTH_ADMIN_KEY);
  }
  return _autha;
}
export function removeAAuth() {
  _s.removeItem(AUTH_ADMIN_KEY);
  _autha = '';
}

// 用户所属学校
export function setSchool(auth) {
  _s.setItem(AUTH_SCHOOL, auth);
  school = auth;
}
export function getSchool() {
  if (!school) {
    school = _s.getItem(AUTH_SCHOOL);
  }
  return school;
}
export function removeSchool() {
  _s.removeItem(AUTH_SCHOOL);
  school = '';
}



// export function getCookie() {
//   var cookieStr = unescape(document.cookie);
//   var arr = cookieStr.split("; ");
//   var cookieValue = "";
//   for (var i = 0; i < arr.length; i++) {
//     var temp = arr[i].split("=");
//     if (temp[0] == 'uucenter-uuloginid') {
//       cookieValue = temp[1];
//       break;
//     }
//   }
//   return cookieValue;
// }
// export function clearCookie() {
//   var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
//   if (keys) {
//     for (var i = keys.length; i--;) {
//       document.cookie =
//         keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); //清除当前域名下的,例如：m.kevis.com
//       document.cookie =
//         keys[i] +
//         "=0;path=/;domain=" +
//         document.domain +
//         ";expires=" +
//         new Date(0).toUTCString(); //清除当前域名下的，例如 .m.kevis.com
//       document.cookie =
//         keys[i] +
//         "=0;path=/;domain=kevis.com;expires=" +
//         new Date(0).toUTCString(); //清除一级域名下的或指定的，例如 .kevis.com
//     }
//   }

// }
