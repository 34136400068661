import request from '@/api/request.js';
// 校验appId

export function checkoutAppId(data) {
    return request({
        url: 'admin/api/oauth/checkoutAppId',
        method: 'post',
        data: data,
    });
}
// 三方授权登录

export function login(data) {
    return request({
        url: 'api/oauth/login',
        method: 'post',
        data: data,
    });
}
