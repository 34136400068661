export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'meLogistics',
    name: "meLogisticsz",
    meta: {
        layout: 'NoLayout',
        title: "Info Collect",
    },
    children: [
        {
            path: 'meLogistics',
            component: () => import('@/views/seller/shop/logistics/meLogistics..vue'),
            name: "meLogistics",
            meta: {
                title: '我的物流',
                layout: 'LayShopCenter',
                userType: 2,
                name: "我的物流",
                pageType: 1,
                validation: true,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的物流", path: false },
                ]
            },
        },
        {
            path: 'batchDelivery',
            component: () => import('@/views/seller/shop/logistics/batchDelivery.vue'),
            name: "batchDelivery",
            meta: {
                title: '批次出货',
                layout: 'LayShopCenter',
                userType: 2,
                name: "批次出货",
                pageType: 1,
                validation: true,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的物流", path: "meLogistics" },
                    { name: "批次出货", path: false },
                ]
            },
        },
        {
            path: 'logisticsSet',
            component: () => import('@/views/seller/shop/logistics/logisticsSet.vue'),
            name: "logisticsSet",
            meta: {
                title: '物流设置',
                layout: 'LayShopCenter',
                userType: 2,
                name: "物流设置",
                pageType: 1,
                validation: true,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的物流", path: "meLogistics" },
                    { name: "物流设置", path: false },
                ]
            },
        },

    ]
}