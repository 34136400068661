<template>
  <div>
    <ShopOpenHead></ShopOpenHead>
    <router-view></router-view>
  </div>
</template>
<script>
import ShopOpenHead from "./components/head/ShopOpenHead";
export default {
  components: {
    ShopOpenHead,
  },
};
</script>