<template>
  <div class="layAdmin">
    <div>
      <AdminHead></AdminHead>
    </div>
    <div style="display: flex; height: 100%">
      <div style="background: #fff; height: 100%"><AdminMenu></AdminMenu></div>
      <div style="padding: 10px; overflow: scroll; width: 100%">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "@/layout/components/menu/AdminMenu";
import AdminHead from "@/layout/components/head/AdminHead";
export default {
  components: {
    AdminMenu,
    AdminHead,
  },
};
</script>
<style lang="less" scoped>
.layAdmin {
  padding-top: 56px;
  background: #f6f6f6;
  width: 100%;
  height: 100%;
}
</style>