module.exports = [
    {
        id: 1, lang: "简体中文(中国)", type: "zh"

    }, {
        id: 2, lang: "English", type: "en"

    }
    // , {
    //     id: 3, lang: "简体中文(马来)",

    // }
    // , {
    //     id: 4, lang: "繁體中文",

    // }
    // , {
    //     id: 5, lang: "ไทย (Thai)",

    // }


]