<template>
  <div>
    <div
      style="height: 100px; width: 100%"
      v-if="$route.path != '/inProcess'"
    ></div>
    <div
      class="footer"
      :style="{ position: $route.path == '/inProcess' ? 'relative' : 'fixed' }"
    >
      <div class="continer">
        <div
          style="
            width: 1200px;
            margin: 0 auto;
            display: flex;
            align-items: center;
          "
        >
          <a href="record.beian_url " style="color: rgba(0, 0, 0, 0.54)"></a>
          <img
            :src="baImg"
            style="vertical-align: top; margin: 2px 5px 0 5px"
          /><a
            href="record.security_addr"
            style="color: rgba(0, 0, 0, 0.54)"
          ></a>
          <span style="margin-left: 5px"
            >CopyRight © {{ $store.state.baseInfo.copyright }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      baImg: require("@/assets/img/app/beian.png"),
    };
  },

};
</script>
<style lang="less" scoped>
.footer {
  background: #fff;
  width: 100%;
  height: 100px;
  border-top: 1px solid #e9e9e9;
  z-index: 10;
  // position: fixed;
  bottom: 0;
  .continer {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icp {
      font-size: 14px;
      color: rgba(25, 37, 55, 0.5);
      letter-spacing: 0.4px;
      line-height: 14px;
    }
    .copy {
      font-size: 14px;
      color: rgba(25, 37, 55, 0.5);
      letter-spacing: 0.4px;
      line-height: 14px;
    }
  }
}
</style>