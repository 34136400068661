export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'orderList',
    name: "orderListz",
    meta: {
        title: '订单',
        layout: 'NoLayout',
        userType: 2,
        name: "订单",
        pageType: 1,
    },
    children: [
        {
            path: 'orderList',
            component: () => import('@/views/seller/shop/order/orderList.vue'),
            name: "orderList",
            meta: {
                title: '订单列表',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的销售", path: false },
                ]
            },
        },
        {
            path: 'orderInfo',
            component: () => import('@/views/seller/shop/order/orderInfo.vue'),
            name: "orderInfo",
            meta: {
                title: '订单列表',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
           
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的销售", path: "orderList" },
                    { name: "订单", path: false },
                ]
            },
        }
    ]
}