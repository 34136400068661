<template>
  <div>
    <BuyHead></BuyHead>
    <LayMain> </LayMain>
    <HomeFoot
      v-if="$route.name!='buySignup'&&$route.name!='buyForget2'"
    ></HomeFoot>
  </div>
</template>
<script>
import BuyHead from "./components/head/BuyHead.vue";
import LayMain from "./components/LayMain.vue";
import HomeFoot from "./components/foot/HomeFoot";
export default {
  components: {
    LayMain,
    BuyHead,
    HomeFoot,
  },
};
</script>
<style lang="less" scoped>
</style>