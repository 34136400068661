<template>
  <div class="sp-toast">
    <div class="toast">
      <i
        class="el-icon-error"
        style="color: red; font-size: 22px"
        v-if="type == 'error'"
      ></i>
      <i
        class="el-icon-success"
        style="color: #67c23a; font-size: 22px"
        v-if="type == 'success'"
      ></i>
      {{ msg }}
    </div>
  </div>
</template>
<script>
export default {
  name: "Toast",
  props: {
    msg: String,
    type:String
  },
};
</script>
<style lang="less" scoped>
.sp-toast {
  position: fixed;
  z-index: 9999999;
  top: 24px;
  width: 100%;
  .toast {
    max-width: 400px;
    background: #fff;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 #b0b0b0;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    i {
      margin-right: 10px;
    }
  }
}
</style>