export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'marketingCenters',
    name: "marketingCenters",
    meta: {
        title: '营销中心',
        layout: 'NoLayout',
        userType: 2,
        name: "营销中心",
        pageType: 1,
    },
    children: [
        {
            path: 'marketingCenter',
            component: () => import('@/views/seller/shop/marketing/marketingCenter.vue'),
            name: "marketingCenter",
            meta: {
                title: '营销中心',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: false },
                ]
            },
        },
        {
            path: 'salesPromotion',
            component: () => import('@/views/seller/shop/marketing/salesPromotion.vue'),
            name: "salesPromotion",
            meta: {
                title: '我的促销活动',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "促销活动", path: false },
                ]
            },
        },
        {
            path: 'coupons',
            component: () => import('@/views/seller/shop/marketing/coupons/coupons.vue'),
            name: "coupons",
            meta: {
                title: '优惠券',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "优惠券", path: false },
                ]
            },
        },
        {
            path: 'createCoupons',
            component: () => import('@/views/seller/shop/marketing/coupons/createCoupons.vue'),
            name: "createCoupons",
            meta: {
                title: '优惠券',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "优惠券", path: "coupons" },
                    { name: "", path: false },
                ]
            },
        },
        {
            path: 'couponsOrder',
            component: () => import('@/views/seller/shop/marketing/coupons/couponsOrder.vue'),
            name: "couponsOrder",
            meta: {
                title: '优惠券订单',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "优惠券", path: "coupons" },
                    { name: "使用优惠券的订单", path: false },
                ]
            },
        },
        {
            path: 'discount',
            component: () => import('@/views/seller/shop/marketing/discount/discount.vue'),
            name: "discount",
            meta: {
                title: '折扣活动',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "我的折扣活动", path: false },
                ]
            },
        },
        {
            path: 'createDiscount',
            component: () => import('@/views/seller/shop/marketing/discount/createDiscount.vue'),
            name: "createDiscount",
            meta: {
                title: '折扣活动',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "我的折扣活动", path: "discount" },
                    { name: "", path: false },
                ]
            },
        },
        {
            path: 'discountInfo',
            component: () => import('@/views/seller/shop/marketing/discount/discountInfo.vue'),
            name: "discountInfo",
            meta: {
                title: '折扣促销详情',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "我的折扣活动", path: "discount" },
                    { name: "折扣促销详情", path: false },
                ]
            },
        },
        {
            path: 'discountData',
            component: () => import('@/views/seller/shop/marketing/discount/discountData.vue'),
            name: "discountData",
            meta: {
                title: '折扣促销详情',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "我的折扣活动", path: "discount" },
                    { name: "数据详情", path: false },
                ]
            },
        },
        {
            path: 'suit',
            component: () => import('@/views/seller/shop/marketing/suit/suit.vue'),
            name: "suit",
            meta: {
                title: '折扣促销详情',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "套装优惠", path: false },
                ]
            },
        },
        {
            path: 'createSuit',
            component: () => import('@/views/seller/shop/marketing/suit/createSuit.vue'),
            name: "createSuit",
            meta: {
                title: '折扣促销详情',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "套装优惠", path: "suit" },
                    { name: "", path: false },
                ]
            },
        },
        {
            path: 'suitInfo',
            component: () => import('@/views/seller/shop/marketing/suit/suitInfo.vue'),
            name: "suitInfo",
            meta: {
                title: '折扣促销详情',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "套装优惠", path: "suit" },
                    { name: "套装优惠详情", path: false },
                ]
            },
        },
        {
            path: 'suitOrder',
            component: () => import('@/views/seller/shop/marketing/suit/suitOrder.vue'),
            name: "suitOrder",
            meta: {
                title: '折扣促销详情',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "套装优惠", path: "suit" },
                    { name: "订单和数据", path: false },
                ]
            },
        },
        {
            path: 'limit',
            component: () => import('@/views/seller/shop/marketing/limit/limit.vue'),
            name: "limit",
            meta: {
                title: '商店的限时选购',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "商店的限时选购", path: false },
                ]
            },
        },
        {
            path: 'createLimit',
            component: () => import('@/views/seller/shop/marketing/limit/createLimit.vue'),
            name: "createLimit",
            meta: {
                title: '商店的限时选购',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "商店的限时选购", path: "limit" },
                    { name: "", path: false },
                ]
            },
        },
        {
            path: 'limitData',
            component: () => import('@/views/seller/shop/marketing/limit/limitData.vue'),
            name: "limitData",
            meta: {
                title: '商店的限时选购',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的行销中心", path: "marketingCenter" },
                    { name: "商店的限时选购", path: "limit" },
                    { name: "数据详情", path: false },
                ]
            },
        },
        {
            path: 'advertising',
            component: () => import('@/views/seller/shop/marketing/advertising/advertising.vue'),
            name: "advertising",
            meta: {
                title: 'shopee广告',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "Shopee广告", path: false },
                ]
            },
        },
        {
            path: 'addAdvertising',
            component: () => import('@/views/seller/shop/marketing/advertising/addAdvertising.vue'),
            name: "addAdvertising",
            meta: {
                title: '创建搜索广告',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "Shopee广告", path: "advertising" },
                    { name: "创建搜索广告", path: false },
                ]
            },
        },
        {
            path: 'topUp',
            component: () => import('@/views/seller/shop/marketing/advertising/topUp.vue'),
            name: "topUp",
            meta: {
                title: '充值',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "Shopee广告", path: "advertising" },
                    { name: "充值", path: false },
                ]
            },
        },
        {
            path: 'pay',
            component: () => import('@/views/seller/shop/marketing/advertising/pay.vue'),
            name: "pay",
            meta: {
                title: '结账',
                layout: 'LayShopCenter2',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "Shopee广告", path: "advertising" },
                    { name: "结账", path: false },
                ]
            },
        },
    ]
}