import { render, staticRenderFns } from "./LayAdmin.vue?vue&type=template&id=7ba0a9ec&scoped=true&"
import script from "./LayAdmin.vue?vue&type=script&lang=js&"
export * from "./LayAdmin.vue?vue&type=script&lang=js&"
import style0 from "./LayAdmin.vue?vue&type=style&index=0&id=7ba0a9ec&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ba0a9ec",
  null
  
)

export default component.exports