import request from '@/api/request.js';
// 管理后台登录
export function adminLogin(data) {
    return request({
        url: 'manager/api/admin/login',
        method: 'post',
        data: data
    });
}

// 删除文件
export function delFile(data) {
    return request({
        url: 'manager/api/upload/delete',
        method: 'post',
        data: data
    });
}
// 获取图形验证码
export function getVerificationCode(data) {
    return request({
        url: 'manager/api/admin/getVerificationCode',
        method: 'post',
        data: data
    });
}
// 退出登录
export function adminLogout(data) {
    return request({
        url: 'manager/api/admin/logout',
        method: 'post',
        data: data
    });
}
// 首页管理
export function homeData(data) {
    return request({
        url: 'manager/api/index/index',
        method: 'post',
        data: data
    });
}

// 首页管理 - 4-班级得分排行榜
export function classTopTen(data) {
    return request({
        url: 'manager/api/index/classTopTen',
        method: 'post',
        data: data
    });
}


// 首页管理 - 5-班级专项得分排名
export function classSpecialTop(data) {
    return request({
        url: 'manager/api/index/classSpecialTop',
        method: 'post',
        data: data
    });
}
// 订单统计

export function getOrderStatics(data) {
    return request({
        url: 'manager/api/index/getOrderStatics',
        method: 'post',
        data: data
    });
}
// 销售额统计
export function getSalesStatics(data) {
    return request({
        url: 'manager/api/index/getSalesStatics',
        method: 'post',
        data: data
    });
}


// 店铺管理
// 店铺列表
export function shopList(data) {
    return request({
        url: 'manager/api/shop/list',
        method: 'post',
        data: data
    });
}
// 店铺详情
export function shopInfo(data) {
    return request({
        url: 'manager/api/shop/details',
        method: 'get',
        params: data
    });
}

// 商品管理
// 商品列表
export function goodsList(data) {
    return request({
        url: 'manager/api/goods/list',
        method: 'post',
        data: data
    });
}
// 商品详情
export function goodsInfo(data) {
    return request({
        url: 'manager/api/goods/details',
        method: 'get',
        params: data
    });
}
// 商品上下架
export function changeStatus(data) {
    return request({
        url: 'manager/api/goods/changeStatus',
        method: 'post',
        data: data
    });
}
// 分类列表
export function categoryList(data) {
    return request({
        url: 'manager/api/category/list',
        method: 'post',
        data: data
    });
}

// 添加分类
export function categoryAdd(data) {
    return request({
        url: 'manager/api/category/add',
        method: 'post',
        data: data
    });
}
// 编辑分类
export function categoryEdit(data) {
    return request({
        url: 'manager/api/category/edit',
        method: 'put',
        data: data
    });
}
// 删除分类
export function categoryDel(data) {
    return request({
        url: 'manager/api/category/delete',
        method: 'delete',
        data: data
    });
}


// 订单管理
// 订单列表
export function orderList(data) {
    return request({
        url: 'manager/api/order/list',
        method: 'post',
        data: data
    });
}
// 订单详情
export function orderInfo(data) {
    return request({
        url: 'manager/api/order/details',
        method: 'get',
        params: data
    });
}
// 退款订单列表
export function refundOrderList(data) {
    return request({
        url: 'manager/api/refund/list',
        method: 'post',
        data: data
    });
}
// 退货订单列表
export function refundOrderList2(data) {
    return request({
        url: 'manager/api/refund/lst',
        method: 'post',
        data: data
    });
}
// 退货/退款详情
export function refOrderInfo(data) {
    return request({
        url: 'manager/api/refund/details',
        method: 'get',
        params: data
    });
}
// 评价列表
export function commentList(data) {
    return request({
        url: 'manager/api/comment/list',
        method: 'post',
        data: data
    });
}

// 删除评价
export function commentDel(data) {
    return request({
        url: 'manager/api/comment/delete',
        method: 'delete',
        data: data
    });
}

// 营销管理
// 优惠券列表
export function couponList(data) {
    return request({
        url: 'manager/api/coupon/list',
        method: 'post',
        data: data
    });
}
// 优惠券详情
export function couponDetails(data) {
    return request({
        url: 'manager/api/coupon/details',
        method: 'post',
        data: data
    });
}
// 优惠券订单列表
export function couponoOrderList(data) {
    return request({
        url: 'manager/api/coupon/orderList',
        method: 'post',
        data: data
    });
}
// 优惠券结束
export function couponFinish(data) {
    return request({
        url: 'manager/api/coupon/finish',
        method: 'post',
        data: data
    });
}
// 折扣活动列表
export function discountList(data) {
    return request({
        url: 'manager/api/discount/list',
        method: 'post',
        data: data
    });
}
// 折扣活动详情
export function discountDetails(data) {
    return request({
        url: 'manager/api/discount/details',
        method: 'post',
        data: data
    });
}
// 折扣活动订单
export function discountOrderList(data) {
    return request({
        url: 'manager/api/discount/orderList',
        method: 'post',
        data: data
    });
}
// 折扣活动结束
export function discountFinish(data) {
    return request({
        url: 'manager/api/discount/finish',
        method: 'post',
        data: data
    });
}
// 套装优惠列表
export function suitList(data) {
    return request({
        url: 'manager/api/suit/list',
        method: 'post',
        data: data
    });
}
// 套装优惠详情
export function suitDetails(data) {
    return request({
        url: 'manager/api/suit/details',
        method: 'post',
        data: data
    });
}

// 套装优惠订单列表
export function suitOrderList(data) {
    return request({
        url: 'manager/api/suit/orderList',
        method: 'post',
        data: data
    });
}


// 套装优惠结束
export function suitFinish(data) {
    return request({
        url: 'manager/api/suit/finish',
        method: 'post',
        data: data
    });
}
// 限时选购列表
export function limitList(data) {
    return request({
        url: 'manager/api/limit/list',
        method: 'post',
        data: data
    });
}
// 限时选购详情
export function limitDetails(data) {
    return request({
        url: 'manager/api/limit/details',
        method: 'post',
        data: data
    });
}
// 限时选购订单列表
export function limitOrderList(data) {
    return request({
        url: 'manager/api/limit/orderList',
        method: 'post',
        data: data
    });
}
// 限时选购结束
export function limitFinish(data) {
    return request({
        url: 'manager/api/limit/finish',
        method: 'post',
        data: data
    });
}
// 获取用户列表
export function userList(data) {
    return request({
        url: 'manager/api/admin/list',
        method: 'post',
        data: data
    });
}
// 编辑用户
export function editU(data) {
    return request({
        url: 'manager/api/admin/edit',
        method: 'post',
        data: data
    });
}
// 获取所属学校列表
export function getSchoolList(data) {
    return request({
        url: 'manager/api/school/getSchoolList',
        method: 'get',
        params: data
    });
}

// 获取角色列表
export function roleList(data) {
    return request({
        url: 'manager/api/role/lst',
        method: 'post',
        data: data
    });
}
// 获取全部角色
export function getAllRole(data) {
    return request({
        url: 'manager/api/role/getAllRole',
        method: 'get',
        params: data
    });
}
// 添加角色
export function operatorAdd(data) {
    return request({
        url: 'manager/api/role/add',
        method: 'post',
        data: data
    });
}
// 编辑角色
export function operatorEdit(data) {
    return request({
        url: 'manager/api/role/edit',
        method: 'post',
        data: data
    });
}
// 获取角色信息
export function getRoleInfo(data) {
    return request({
        url: 'manager/api/role/getRoleInfo',
        method: 'post',
        data: data
    });
}
// 停用/启用角色
export function delRole(data) {
    return request({
        url: 'manager/api/role/delRole',
        method: 'post',
        data: data
    });
}


// 权限菜单
// 获取权限列表
export function nodeList(data) {
    return request({
        url: 'manager/api/node/index',
        method: 'post',
        data: data
    });
}

// 添加菜单
export function nodeAdd(data) {
    return request({
        url: 'manager/api/node/add',
        method: 'post',
        data: data
    });
}
// 编辑菜单
export function nodeEdit(data) {
    return request({
        url: 'manager/api/node/edit',
        method: 'post',
        data: data
    });
}
// 删除菜单
export function nodeDel(data) {
    return request({
        url: 'manager/api/node/delete',
        method: 'delete',
        data: data
    });
}

//学校管理
// 学校列表
export function schoolList(data) {
    return request({
        url: 'manager/api/school/list',
        method: 'post',
        data: data
    });
}
// 教师列表
export function teacherList(data) {
    return request({
        url: 'manager/api/teacher/list',
        method: 'post',
        data: data
    });
}

// 学校管理 - 2.2-教师评分

export function getTeacherPointList1(data) {
    return request({
        url: 'manager/api/teacher/getTeacherPointList',
        method: 'post',
        data: data
    });
}
// 学校管理 - 2.2.1-导出教师评分

export function exportTeacherScore1(data) {
    return request({
        url: 'manager/api/teacher/exportTeacherScore',
        method: 'get',
        responseType: 'blob',
        params: data
    });
}


// 学校管理 - 2.2.2-获取教师评分详情
export function getTeacherScoreDetail(data) {
    return request({
        url: 'manager/api/teacher/getTeacherScoreDetail',
        method: 'post',
        data: data
    });
}

// 学校管理 - 2.2.3-保存教师评分

export function saveTeacherScoreS(data) {
    return request({
        url: 'manager/api/teacher/saveTeacherScore',
        method: 'post',
        data: data
    });
}

// 学校管理 - 2.2.4-跳转
export function examStudentLogin(data) {
    return request({
        url: 'manager/api/teacher/examStudentLogin',
        method: 'post',
        data: data
    });
}

// 班级列表
export function classList(data) {
    return request({
        url: 'manager/api/class/list',
        method: 'post',
        data: data
    });
}
// 所有班级
export function getClassList(data) {
    return request({
        url: 'manager/api/class/getClassList',
        method: 'get',
        params: data
    });
}
//会员管理
//学生列表
export function studentList(data) {
    return request({
        url: 'manager/api/student/list',
        method: 'post',
        data: data
    });
}
//买家列表
export function buyerList(data) {
    return request({
        url: 'manager/api/buyer/list',
        method: 'post',
        data: data
    });
}


// 常规管理
// 公告管理-列表
export function noticeList(data) {
    return request({
        url: 'manager/api/notice/list',
        method: 'post',
        data: data
    });
}

// 添加公告
export function noticeAdd(data) {
    return request({
        url: 'manager/api/notice/add',
        method: 'post',
        data: data
    });
}

// 编辑公告
export function noticeEdit(data) {
    return request({
        url: 'manager/api/notice/edit',
        method: 'put',
        data: data
    });
}
// 删除公告
export function noticeDel(data) {
    return request({
        url: 'manager/api/notice/delete',
        method: 'delete',
        data: data
    });
}
// 轮播管理-列表
export function bannerList(data) {
    return request({
        url: 'manager/api/banner/list',
        method: 'post',
        data: data
    });
}

// 添加轮播
export function bannerAdd(data) {
    return request({
        url: 'manager/api/banner/add',
        method: 'post',
        data: data
    });
}

// 编辑轮播
export function bannerEdit(data) {
    return request({
        url: 'manager/api/banner/edit',
        method: 'put',
        data: data
    });
}
// 删除轮播
export function bannerDel(data) {
    return request({
        url: 'manager/api/banner/delete',
        method: 'delete',
        data: data
    });
}

// 广告管理-列表
export function advertList(data) {
    return request({
        url: 'manager/api/advert/list',
        method: 'post',
        data: data
    });
}

// 添加广告
export function advertAdd(data) {
    return request({
        url: 'manager/api/advert/add',
        method: 'post',
        data: data
    });
}

// 编辑广告
export function advertEdit(data) {
    return request({
        url: 'manager/api/advert/edit',
        method: 'put',
        data: data
    });
}
// 删除广告
export function advertDel(data) {
    return request({
        url: 'manager/api/advert/delete',
        method: 'delete',
        data: data
    });
}

// 搜索词管理-列表
export function searchList(data) {
    return request({
        url: 'manager/api/search/list',
        method: 'get',
        params: data
    });
}

// 删除搜索词
export function searchDel(data) {
    return request({
        url: 'manager/api/search/delete',
        method: 'delete',
        data: data
    });
}

// 系统设置
// 获取系统设置列表
export function setConfig(data) {
    return request({
        url: 'manager/api/setConfig/getConfig',
        method: 'post',
        data: data
    });
}

// 设置配置
export function setConfigSet(data) {
    return request({
        url: 'manager/api/setConfig/set',
        method: 'post',
        data: data
    });
}

// 评分管理 - 1.0-学校列表

export function getCommentSchoolList(data) {
    return request({
        url: 'manager/api/score/getCommentSchoolList',
        method: 'post',
        data: data
    });
}

// 评分管理 - 1.2-教师评分

export function getTeacherPointList(data) {
    return request({
        url: 'manager/api/score/getTeacherPointList',
        method: 'post',
        data: data
    });
}


// 评分管理 - 1.3-保存教师评分

export function saveTeacherScore(data) {
    return request({
        url: 'manager/api/score/saveTeacherScore',
        method: 'post',
        data: data
    });
}
// 评分管理 - 1.4-导出教师评分

export function exportTeacherScore(data) {
    return request({
        url: 'manager/api/score/exportTeacherScore',
        method: 'get',
        responseType: 'blob',
        params: data
    });
}

// 实训成绩 - 1.1-得分,订单,利润排行榜
export function getBankList(data) {
    return request({
        url: 'manager/api/score/getBankList',
        method: 'post',
        data: data
    });
}


// 实训成绩 - 1.2-查看得分详情
export function getFlowDetail(data) {
    return request({
        url: 'manager/api/score/getFlowDetail',
        method: 'post',
        data: data
    });
}

// 实训成绩 - 1.3-导出排行榜
export function exportBank(data) {
    return request({
        url: 'manager/api/score/exportBank',
        method: 'get',
        responseType: 'blob',
        params: data
    });
}
// 实训成绩 - 1.2-获取操作组件
export function getOperationComponents(data) {
    return request({
        url: 'manager/api/score/getOperationComponents',
        method: 'post',
        data: data
    });
}

// 基础组件管理 - 1.1-获取基础组件

export function getBaseList(data) {
    return request({
        url: 'manager/api/score/getBaseList',
        method: 'get',
        params: data
    });
}

// 基础组件管理 - 1.2-获取组件列表


export function getRatingList(data) {
    return request({
        url: 'manager/api/score/getRatingList',
        method: 'get',
        params: data
    });
}
// 基础组件管理 - 1.3-保存组件
export function saveRatings(data) {
    return request({
        url: 'manager/api/score/saveRating',
        method: 'post',
        data: data
    });
}

// 基础组件管理 - 1.4-删除组件

export function delRating(data) {
    return request({
        url: 'manager/api/score/delRating',
        method: 'delete',
        data: data
    });
}



// 评分组件管理 - 1.1-获取评分组件列表
export function getBasicList(data) {
    return request({
        url: 'manager/api/score/getBasicList',
        method: 'get',
        params: data
    });
}

// 评分组件管理 - 1.2-获取所有的评分项分类

export function getCateList(data) {
    return request({
        url: 'manager/api/score/getCateList',
        method: 'get',
        params: data
    });
}
// 评分组件管理 - 1.3-获取评分点列表
export function getScoringPointsList(data) {
    return request({
        url: 'manager/api/score/getScoringPointsList',
        method: 'get',
        params: data
    });
}
// 评分组件管理 - 1.4-保存评分项

export function saveBasic(data) {
    return request({
        url: 'manager/api/score/saveBasic',
        method: 'post',
        data: data
    });
}

// 评分组件管理 - 1.5-删除评分项

export function delBasic(data) {
    return request({
        url: 'manager/api/score/delBasic',
        method: 'delete',
        data: data
    });
}

// 评测相关配置管理 - 1.1-获取相关配置

export function getConfigData(data) {
    return request({
        url: 'manager/api/score/getConfigData',
        method: 'post',
        data: data
    });
}

// 运营组件管理 - 1.1-获取运营组件列表

export function getOperationList(data) {
    return request({
        url: 'manager/api/score/getOperationList',
        method: 'get',
        params: data
    });
}

// 运营组件管理 - 1.2 - 保存运营组件

export function saveOperation(data) {
    return request({
        url: 'manager/api/score/saveOperation',
        method: 'post',
        data: data
    });
}

// 运营组件管理 - 1.3-删除运营组件

export function delOperation(data) {
    return request({
        url: 'manager/api/score/delOperation',
        method: 'delete',
        data: data
    });
}

// 运营分详情 - 1.1-首单、持续出单分数


export function getFirstOrderList(data) {
    return request({
        url: 'manager/api/score/getFirstOrderList',
        method: 'post',
        data: data
    });
}

// 运营分详情 - 1.2-得分详情

export function getProductDetail(data) {
    return request({
        url: 'manager/api/score/getProductDetail',
        method: 'post',
        data: data
    });
}

// 教师模块管理 - 1.1-获取教师模块列表

export function getTeacherModuleList(data) {
    return request({
        url: 'manager/api/score/getTeacherModuleList',
        method: 'get',
        params: data
    });
}

// 教师模块管理 - 1.2-保存教师模块

export function saveTeacherModule(data) {
    return request({
        url: 'manager/api/score/saveTeacherModule',
        method: 'post',
        data: data
    });
}
// 学校管理 - 4.1-实训成绩列表

export function getScoreBankList(data) {
    return request({
        url: 'manager/api/training/getScoreBankList',
        method: 'post',
        data: data
    });
}
// 学校管理 - 4.1.5-获取用户统计信息
export function getUserInfo(data) {
    return request({
        url: 'manager/api/training/getUserInfo',
        method: 'post',
        data: data
    });
}

// 学校管理 - 4.1.6-获取学生得分详情

export function getUserScoreDetail(data) {
    return request({
        url: 'manager/api/training/getUserScoreDetail',
        method: 'post',
        data: data
    });
}

// 学校管理 - 4.1.7-获取学生流程分详情


export function getUserFlowDetail(data) {
    return request({
        url: 'manager/api/training/getUserFlowDetail',
        method: 'post',
        data: data
    });
}

// 学校管理 - 4.1.8-获取学生利润分详情

export function getUserProfitDetail(data) {
    return request({
        url: 'manager/api/training/getUserProfitDetail',
        method: 'post',
        data: data
    });
}

// 学校管理 - 4.1.9-获取学生运营产品详情
export function getUserOperationProductDetail(data) {
    return request({
        url: 'manager/api/training/getUserOperationProductDetail',
        method: 'post',
        data: data
    });
}

// 学校管理 - 4.2.0-获取学生运营订单详情
export function getUserOperationOrderDetail(data) {
    return request({
        url: 'manager/api/training/getUserOperationOrderDetail',
        method: 'post',
        data: data
    });
}

// 学校管理 - 4.1.8-获取用户店铺列表
export function getUserShopList(data) {
    return request({
        url: 'manager/api/training/getUserShopList',
        method: 'post',
        data: data
    });
}




// 学校管理 - 4.1.9-获取教师评分详情

export function getUserTeacherDetail(data) {
    return request({
        url: 'manager/api/training/getUserTeacherDetail',
        method: 'post',
        data: data
    });
}






// 学校管理 - 4.1.1-获取操作组件
export function getOperationComponentsS(data) {
    return request({
        url: 'manager/api/training/getOperationComponents',
        method: 'post',
        data: data
    });
}

// 学校管理 - 4.1.2-获取用户模块分数详情
export function getUserModuleScoreDetail(data) {
    return request({
        url: 'manager/api/training/getUserModuleScoreDetail',
        method: 'post',
        data: data
    });
}
// 学校管理 - 4.1.3-获取流程得分详情

export function getFlowDetailS(data) {
    return request({
        url: 'manager/api/training/getFlowDetail',
        method: 'post',
        data: data
    });
}
// 学校管理 - 4.1.4-导出实训成绩


export function exportTrainingScore(data) {
    return request({
        url: 'manager/api/training/exportTrainingScore',
        method: 'get',
        responseType: 'blob',
        params: data
    });
}




// AI地址管理 - 1.1-地址列表


export function getAddressList(data) {
    return request({
        url: 'manager/api/ai/getAddressList',
        method: 'get',
        params: data
    });
}

// AI智能管理 - 1.2-保存地址
export function saveAddress(data) {
    return request({
        url: 'manager/api/ai/saveAddress',
        method: 'post',
        data: data
    });
}

// AI智能管理 - 1.3-启用/禁用
export function changeStatusAddr(data) {
    return request({
        url: 'manager/api/ai/changeStatus',
        method: 'post',
        data: data
    });
}

// AI智能管理 - 3.1-获取买家常用语


export function getRobotKfList(data) {
    return request({
        url: 'manager/api/ai/getRobotKfList',
        method: 'get',
        params: data
    });
}

// AI智能管理 - 3.2-新增/编辑买家常用语

export function saveRobotKfCommon(data) {
    return request({
        url: 'manager/api/ai/saveRobotKfCommon',
        method: 'post',
        data: data
    });
}
// AI智能管理 - 3.3-更新买家常用语状态

export function updateKfStatus(data) {
    return request({
        url: 'manager/api/ai/updateKfStatus',
        method: 'post',
        data: data
    });
}


// AI智能管理 - 2.1-买家机器人列表

export function getRobotList(data) {
    return request({
        url: 'manager/api/ai/getRobotList',
        method: 'get',
        params: data
    });
}



// AI智能管理 - 2.2-新增/编辑机器人

export function saveRobot(data) {
    return request({
        url: 'manager/api/ai/saveRobot',
        method: 'post',
        data: data
    });
}


// AI智能管理 - 2.3-获取机器人详情

export function getRobotInfo(data) {
    return request({
        url: 'manager/api/ai/getRobotInfo',
        method: 'get',
        params: data
    });
}

// AI智能管理 - 2.4-修改买家机器人状态


export function updateRobotStatus(data) {
    return request({
        url: 'manager/api/ai/updateRobotStatus',
        method: 'post',
        data: data
    });
}

// AI智能管理 - 4.1-获取买家评语列表


export function getBuyerCommentList(data) {
    return request({
        url: 'manager/api/ai/getBuyerCommentList',
        method: 'get',
        params: data
    });
}

// AI智能管理 - 4.2-新增/编辑买家评语
export function saveBuyerCommon(data) {
    return request({
        url: 'manager/api/ai/saveBuyerCommon',
        method: 'post',
        data: data
    });
}

// AI智能管理 - 4.4-更新买家评语状态
export function upBuyerCommentStatus(data) {
    return request({
        url: 'manager/api/ai/upBuyerCommentStatus',
        method: 'post',
        data: data
    });
}

