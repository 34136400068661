export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'buyLogin',
    name: "buyLoginz",
    meta: {
        title: '买家登录',
        layout: 'NoLayout',
        // userType: 3,
        // validation: true

    },
    children: [
        {
            path: 'buyLogin',
            component: () => import('@/views/buyer/buyLogin.vue'),
            name: "buyLogin",
            meta: {
                title: '买家登录',
                layout: 'LayBuyLogin',
                // userType: 3,
                pageType: true
                // validation: true
            },
        },
        {
            path: 'buySignup',
            component: () => import('@/views/buyer/buySignup.vue'),
            name: "buySignup",
            meta: {
                title: '注册',
                layout: 'LayBuySignup',
                pageType: true,

            },
        },
        {
            path: 'buyForget',
            component: () => import('@/views/buyer/buyForget.vue'),
            name: "buyForget",
            meta: {
                title: '重设密码',
                layout: 'LayBuySignup',
                pageType: true,
            },
        },
        {
            path: 'buyForget2',
            component: () => import('@/views/buyer/buyForget2.vue'),
            name: "buyForget2",
            meta: {
                title: '重设密码',
                layout: 'LayBuySignup',
                pageType: true,
            },
        },
        {
            path: 'buyHome',
            component: () => import('@/views/buyer/buyHome.vue'),
            name: "buyHome",
            meta: {
                title: '个人资料',
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                validation: true
            },
        },
        {
            path: 'buyAddress',
            component: () => import('@/views/buyer/buyAddress.vue'),
            name: "buyAddress",
            meta: {
                title: '买家地址',
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                validation: true
            },
        },
        {
            path: 'buyEditPwd',
            component: () => import('@/views/buyer/buyEditPwd.vue'),
            name: "buyEditPwd",
            meta: {
                title: '更改密码',
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                validation: true
            },
        },
        {
            path: 'buyListing',
            component: () => import('@/views/buyer/buyListing.vue'),
            name: "buyListing",
            meta: {
                title: '购买清单',
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                chatShow: true,
                validation: true

            },
        },
        {
            path: 'buyOrderInfo',
            component: () => import('@/views/buyer/buyOrderInfo.vue'),
            name: "buyOrderInfo",
            meta: {
                title: '清单详情',
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                validation: true

            },
        },
        {
            path: 'buyOrderRef',
            component: () => import('@/views/buyer/buyOrderRef.vue'),
            name: "buyOrderRef",
            meta: {
                title: '退货/退款',
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                validation: true

            },
        },
        {
            path: 'buyOrderMsg',
            component: () => import('@/views/buyer/buyOrderMsg.vue'),
            name: "buyOrderMsg",
            meta: {
                title: '订单更新通知',
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                validation: true

            },
        },
        {
            path: 'buyUpdateMsg',
            component: () => import('@/views/buyer/buyUpdateMsg.vue'),
            name: "buyUpdateMsg",
            meta: {
                title: 'shopee更新',
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                validation: true

            },
        },
        {
            path: 'buyCoupons',
            component: () => import('@/views/buyer/buyCoupons.vue'),
            name: "buyCoupons",
            meta: {
                title: '我的优惠券',
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                validation: true

            },
        },
        {
            path: 'buyCouponsHs',
            component: () => import('@/views/buyer/buyCouponsHs.vue'),
            name: "buyCouponsHs",
            meta: {
                title: '我的优惠券',
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                validation: true

            },
        },
    ]
}