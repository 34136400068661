import request from '@/api/request.js';

//   ------买家--------
// 获取消息列表
export function getMsgList(data) {
    return request({
        url: 'buyers/api/chat/getMsgList',
        method: 'get',
        params: data,
    });
}
// 获取聊天列表

export function getChatList(data) {
    return request({
        url: 'buyers/api/chat/getChatList',
        method: 'get',
        params: data,
    });
}
// 获取聊天对象
export function getChatWith(data) {
    return request({
        url: 'buyers/api/chat/getChatWith',
        method: 'get',
        params: data,
    });
}
// 添加聊天消息
export function addMsg(data) {
    return request({
        url: 'buyers/api/chat/addMsg',
        method: 'post',
        data: data,
    });
}


//   ------卖家--------
// 获取消息列表
export function getMsgListSeller(data) {
    return request({
        url: 'business/api/chat/getMsgList',
        method: 'get',
        params: data,
    });
}
// 获取聊天列表
export function getChatListSeller(data) {
    return request({
        url: 'business/api/chat/getChatList',
        method: 'get',
        params: data,
    });
}
// 添加聊天消息
export function addMsgSeller(data) {
    return request({
        url: 'business/api/chat/addMsg',
        method: 'post',
        data: data,
    });
}
// 获取右边信息
export function getRightMsg(data) {
    return request({
        url: 'business/api/chat/getRightMsg',
        method: 'get',
        params: data,
    });
}