import axios from 'axios'
import env from '@/settings/env.js';
import { Message } from 'element-ui';
import route from '@/router'
import {
    getAuth,
    getSAuth,
    getBAuth,
    getAAuth,
    getSchool,
    removeAuth,
    removeSAuth,
    removeBAuth,
    removeAAuth
} from '@/utils/auth.js';

const instance = axios.create({
    baseURL: env.apiUrlPrefix,
    timeout: 15000
})
instance.interceptors.request.use(

    config => {

        let auth = getAuth()
        let auths = getSAuth()
        let authb = getBAuth()
        let autha = getAAuth()
        let school = getSchool()
        // let type = localStorage.USERTYPE ? localStorage.USERTYPE : 1
        //用户类型 1.主账号，2.卖家账号，3.买家账号，4.管理后台账号
        let type = route.currentRoute.meta.userType ? route.currentRoute.meta.userType : 1
        if (auth && type == 1) {
            config.headers.Authorization = auth
        }
        if (auths && type == 2) {
            config.headers.Authorization = auths
        }
        if (authb && type == 3) {
            config.headers.Authorization = authb
        }
        if (autha && type == 4) {
            config.headers.Authorization = autha
        }
        if (school) {
            config.headers.school = school
        }

        return config
    }
    , error => {
        return Promise.reject(error);
    }
)


instance.interceptors.response.use(
    res => {

        let name = route.currentRoute.name
        switch (res.data.code) {
            case 0:
                Message({
                    message: res.data.message,
                    type: 'error',
                    duration: 3.5 * 1000
                });

                break;

            case -1:// 登录失败或者登录过期
                // if (userType == 1) {
                //     // removeAuth();
                //     // location.reload();
                // }
                // if (userType == 2) {
                //     // removeSAuth();
                //     // location.reload();
                // }
                // if (userType == 3) {
                //     // removeBAuth();

                //     // location.reload();
                // }
                // if (userType == 4) {
                //     // removeAAuth();
                //     // location.reload();

                // }
                // if(window)
                if (name != 'home') {
                    turnAround()
                }



                // Message({
                //     message: res.data.message,
                //     type: 'error',
                //     duration: 3.5 * 1000
                // });
                // setTimeout(() => {
                //     if (userType == 1) {
                //         removeAuth();
                //     }
                //     if (userType == 2) {
                //         removeSAuth();
                //     }

                //     location.reload();
                // }, 1500);
                // return Promise.reject(new Error(res.data.message || 'Error'));

                break;

            case 6000:
                if (name != 'home') {
                    turnAround()
                }
                break;

            default:
        }
        return res.data
    },
    error => {

        return Promise.reject(error);
    }
);

export default instance;


function turnAround() {
    removeAuth();
    removeSAuth();
    removeBAuth();
    removeAAuth();
    if (sessionStorage.BACKURL) {
        window.location.href = sessionStorage.BACKURL
    }

}