import request from '@/api/request.js';
// 获取首页内容
export function homeInfo(data) {
    return request({
        url: 'buyers/api/index/init',
        method: 'get',
        params: data
    });
}
// 获取热门商品分类
export function hotGoodsCategory(data) {
    return request({
        url: 'buyers/api/goods/hotGoodsCategory',
        method: 'get',
        params: data
    });
}
// 根据分类获取热门商品
export function hotGoods(data) {
    return request({
        url: 'buyers/api/goods/hotGoods',
        method: 'get',
        params: data
    });
}
// 筛选商品
export function searchGoods(data) {
    return request({
        url: 'buyers/api/goods/search',
        method: 'post',
        data: data
    });
}
// 筛选店铺
export function searchStore(data) {
    return request({
        url: 'buyers/api/shop/searchList',
        method: 'get',
        params: data
    });
}
// 商品详情
export function goodsDetail(data) {
    return request({
        url: 'buyers/api/goods/goodsDetail',
        method: 'post',
        data: data
    });
}
// 加入购物车
export function addBuyCart(data) {
    return request({
        url: 'buyers/api/cart/add',
        method: 'post',
        data: data
    });
}
// 购物车列表
export function BuyCartList(data) {
    return request({
        url: 'buyers/api/cart/lists',
        method: 'post',
        data: data
    });
}
// 购物车数量
export function getBuyCartLength(data) {
    return request({
        url: 'buyers/api/cart/getCount',
        method: 'post',
        data: data
    });
}
// 购物车数量
export function delCartGoods(data) {
    return request({
        url: 'buyers/api/cart/delete',
        method: 'post',
        data: data
    });
}
// 编辑购物车商品规格
export function updateCart(data) {
    return request({
        url: 'buyers/api/cart/updateCart',
        method: 'post',
        data: data
    });
}
// 编辑购物车商品数量
export function updateCartNum(data) {
    return request({
        url: 'buyers/api/cart/updateCartNum',
        method: 'post',
        data: data
    });
}
// 购物车相似商品
export function getSimilarGoods(data) {
    return request({
        url: 'buyers/api/goods/getSimilarGoods',
        method: 'get',
        params: data
    });
}
// 购物车猜你喜欢
export function likes(data) {
    return request({
        url: 'buyers/api/goods/likes',
        method: 'get',
        params: data
    });
}
// 店铺主页
export function storeIndex(data) {
    return request({
        url: 'buyers/api/shop/index',
        method: 'get',
        params: data
    });
}

// 所有商品
export function allGoods(data) {
    return request({
        url: 'buyers/api/goods/allGoods',
        method: 'get',
        params: data
    });
}
// 获取店铺分类
export function categoryList(data) {
    return request({
        url: 'buyers/api/shop/categoryList',
        method: 'post',
        data: data
    });
}

//  提交搜索关键字
export function setSearch(data) {
    return request({
        url: 'buyers/api/index/setSearch',
        method: 'post',
        data: data
    });
}

//  商品评论列表
export function goodsCommentList(data) {
    return request({
        url: 'buyers/api/goods/goodsCommentList',
        method: 'post',
        data: data
    });
}

//  获取商品sku数量
export function getSkuNumber(data) {
    return request({
        url: 'buyers/api/goods/getSkuNumber',
        method: 'post',
        data: data
    });
}






