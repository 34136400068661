export function formatTime(time, type) {
    let date = new Date(time * 1000); // 初始化日期
    let year = date.getFullYear(); //获取年份
    let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1; // 获取月份
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); // 获取具体日
    let dqDay = new Date().getDate();
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 获取时
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()// 获取分
    let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds(); // 获取秒
    if (type == 'djs') {
        if (day == dqDay) {
            let syTime = parseInt((date.getTime() - Date.now()) / 3600000) + "小时"
            if (syTime == "0小时") {
                syTime = parseInt((date.getTime() - Date.now()) / 60000) + "分钟"
                if (syTime == "0分钟") {
                    syTime = parseInt((date.getTime() - Date.now()) / 1000) + "秒"
                }
            }
            return syTime;
        } else {
            return year + "/" + month + "/" + day + "/" + " " + hour + ":" + minutes + ":" + seconds;

        }
    } else {
        if (type == "all") {
            return year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds
        } else if (type == "1") {
            return hour + ":" + minutes
        } else {
            if (day == dqDay) {
                return "今天" + " " + hour + ":" + minutes + ":" + seconds;
            } else {
                if (dqDay - day == 1) {
                    return "昨天" + " " + hour + ":" + minutes + ":" + seconds;
                } else {
                    return year + "/" + month + "/" + day + "/" + " " + hour + ":" + minutes + ":" + seconds;
                }
            }
        }

    }

}
export function formatTimeS(time, type) {
    let date = new Date(time * 1000); // 初始化日期
    let year = date.getFullYear(); //获取年份
    let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1; // 获取月份
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); // 获取具体日
    let dqDay = new Date().getDate();
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 获取时
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()// 获取分
    let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds(); // 获取秒
    if (type == "all") {
        return year + "年" + month + "月" + day + "日" + hour + ":" + minutes + ":" + seconds
    } else {
        if (day == dqDay) {
            return "今天" + " " + hour + ":" + minutes;
        } else {
            if (dqDay - day == 1) {
                return "昨天" + " " + hour + ":" + minutes;
            } else {
                return year + "/" + month + "/" + day + "/" + " " + hour + ":" + minutes;
            }
        }
    }

}
export function formatTimeA(time, type) {
    let date = new Date(time); // 初始化日期

    let year = date.getFullYear(); //获取年份
    let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1; // 获取月份
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); // 获取具体日
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 获取时
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()// 获取分
    // let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds(); // 获取秒

    if (type == 1) {
        return year + "年" + month + "月" + day + "日"
        //  + hour + ":" + minutes + ":" + seconds
    }
    if (type == 2) {
        return hour + ":" + minutes

    }
    if (type == 3) {
        return month + "/" + day

    }
    if (type == 4) {
        return year + "-" + month + "-" + day

    }
    if (type == 5) {
        return   month + "月" + day+"日"

    }




}
export default { formatTime, formatTimeS, formatTimeA }