<template>
  <div class="navbar-wrapper">
    <div class="navbar">
      <div class="navbar_l">
        <a href="javascript:void(0);" class="_2QcfeN" @click="toLogin">
          {{ $t("homeNavbar.sellerCentre") }}</a>
        <el-divider direction="vertical"></el-divider>
        <a @click="toAdmin" href="javaScript:;" class="_2QcfeN">
          {{ $t("homeNavbar.immediatelyIn") }}</a>
      </div>
      <div>
        <ul class="navbar_links">
          <!-- <li>
            <a href="javaScript:;" class="flex _2QcfeN">
              <svg viewBox="3 2.5 14 14" x="0" y="0" class="shopee-svg-icon">
                <path
                  d="m17 15.6-.6-1.2-.6-1.2v-7.3c0-.2 0-.4-.1-.6-.3-1.2-1.4-2.2-2.7-2.2h-1c-.3-.7-1.1-1.2-2.1-1.2s-1.8.5-2.1 1.3h-.8c-1.5 0-2.8 1.2-2.8 2.7v7.2l-1.2 2.5-.2.4h14.4zm-12.2-.8.1-.2.5-1v-.1-7.6c0-.8.7-1.5 1.5-1.5h6.1c.8 0 1.5.7 1.5 1.5v7.5.1l.6 1.2h-10.3z"
                ></path>
              </svg>
              <span class="marginL">{{ $t("homeNavbar.notifications") }}</span>
            </a>
          </li>
          <li class="marginL10">
            <a href="javaScript:;" class="flex _2QcfeN">
              <svg
                height="16"
                viewBox="0 0 16 16"
                width="16"
                class="shopee-svg-icon"
              >
                <g fill="none" fill-rule="evenodd" transform="translate(1)">
                  <circle cx="7" cy="8" r="7" stroke="currentColor"></circle>
                  <path
                    fill="currentColor"
                    d="m6.871 3.992c-.814 0-1.452.231-1.914.704-.462.462-.693 1.089-.693 1.892h1.155c0-.484.099-.858.297-1.122.22-.319.583-.473 1.078-.473.396 0 .715.11.935.33.209.22.319.517.319.902 0 .286-.11.55-.308.803l-.187.209c-.682.605-1.1 1.056-1.243 1.364-.154.286-.22.638-.22 1.045v.187h1.177v-.187c0-.264.055-.506.176-.726.099-.198.253-.396.462-.572.517-.451.825-.737.924-.858.275-.352.418-.803.418-1.342 0-.66-.22-1.188-.66-1.573-.44-.396-1.012-.583-1.716-.583zm-.198 6.435c-.22 0-.418.066-.572.22-.154.143-.231.33-.231.561 0 .22.077.407.231.561s.352.231.572.231.418-.077.572-.22c.154-.154.242-.341.242-.572s-.077-.418-.231-.561c-.154-.154-.352-.22-.583-.22z"
                  ></path>
                </g>
              </svg>
              <span class="marginL">{{ $t("homeNavbar.help") }}</span>
            </a>
          </li> -->
          <li style="margin-left: 20px; padding-top: 4px; cursor: pointer">
            <el-dropdown style="color: #fff" @command="switchLangs">
              <span class="el-dropdown-link _2QcfeN" style="font-size: 13px; display: flex; align-items: center">
                <div class="UkG+ar" style="margin-right: 4px; display: flex; align-items: center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.00065 14.6667C11.6825 14.6667 14.6673 11.6819 14.6673 8.00004C14.6673 4.31814 11.6825 1.33337 8.00065 1.33337C4.31875 1.33337 1.33398 4.31814 1.33398 8.00004C1.33398 11.6819 4.31875 14.6667 8.00065 14.6667Z"
                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path
                      d="M5.33464 8.00004C5.33464 11.6819 6.52854 14.6667 8.0013 14.6667C9.47406 14.6667 10.668 11.6819 10.668 8.00004C10.668 4.31814 9.47406 1.33337 8.0013 1.33337C6.52854 1.33337 5.33464 4.31814 5.33464 8.00004Z"
                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M1.33398 8H14.6673" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                    </path>
                  </svg>
                </div>
                {{ name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :style="{ color: name == item.lang ? '#ee4d2d' : '' }" :command="item"
                  v-for="item in langs" :key="item.id">{{ item.lang }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>

          <li style="margin-left: 20px" v-if="!$store.state.buyUserInfo">
            <a @click="toPush(1)" href="javaScript:;" class="_2QcfeN">{{
              $t("homeNavbar.signUp")
              }}</a>
            <el-divider direction="vertical"></el-divider>
          </li>
          <li v-if="!$store.state.buyUserInfo">
            <a @click="toPush(2)" href="javaScript:;" class="_2QcfeN">{{
              $t("homeNavbar.login")
              }}</a>
          </li>
          <li @click="toBuyInfo()" v-if="$store.state.buyUserInfo" style="
              display: flex;
              align-items: center;
              margin-left: 20px;
              cursor: pointer;
            ">
            <el-avatar :size="22" :src="
                $store.state.buyUserInfo.avatar
                  ? $store.state.buyUserInfo.avatar
                  : tx
              "></el-avatar>
            <span style="margin-left: 10px">{{
              $store.state.buyUserInfo.username
              }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import { buyUserInfo } from "@/api/buy.js";
  import { getSAuth } from "@/utils/auth.js";
  import lang from "@/i18n/lang/languages.js";
  import tx from "@/assets/img/home/headImg.png";

  export default {
    data() {
      return {
        langs: lang,
        name: "简体中文(中国)",
        tx: tx,
      };
    },
    created() {
      // this.getBuyUserInfo();
    },
    mounted() {
      this.$i18n.locale = "zh";
      this.getBuyUserInfo();
    },
    methods: {
      switchLangs(item) {
        this.$i18n.locale = item.type;
        this.name = item.lang;
      },
      toLogin() {
        if (getSAuth()) {
          this.$router.push("shopCenter");
        } else {
          this.$router.push("shopLogin");
        }
      },
      toPush(type) {
        // this.$store.state.buyLoginType = type;
        // this.$router.push("buyLogin");
        if (sessionStorage.BACKURL) {
          window.location.href = sessionStorage.BACKURL
        }
      },
      toBuyInfo() {
        this.$router.push("buyHome");
      },
      toAdmin() {
        this.$router.push("seller");
      },
      getBuyUserInfo() {
        buyUserInfo().then((res) => {
          if (res.code == 1) {
            this.$store.state.buyUserInfo = res.data;
          }
          if (res.code == -1) {
            this.$store.state.buyUserInfo = "";
          }
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .navbar-wrapper {
    // background: ;
    height: 34px;

    .navbar {
      width: 1200px;
      margin: 0 auto;
      height: inherit;
      color: #fff;
      font-size: 13px;
      font-weight: 300;
      // padding: 0.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .navbar_links {
        display: flex;
        align-items: center;

        .shopee-svg-icon {
          width: 0.875rem;
          height: 1.125rem;
          fill: currentColor;
        }
      }
    }

    ul,
    ol {
      list-style: none;
    }
  }
</style>