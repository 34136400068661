export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'login',
    name: "login",
    meta: {
        layout: 'NoLayout',
        title: "登入shopee",
    },
    children: [
        {
            path: 'mainLogin',
            component: () => import('@/views/login/mainLogin.vue'),
            name: "mainLogin",
            meta: {
                layout: 'NoLayout',
                title: "登入shopee",
                type: 1,
                validation: true,
                userType: 1
            },
        }, {
            path: 'shopLogin',
            component: () => import('@/views/login/shopLogin.vue'),
            name: "shopLogin",
            meta: {
                layout: 'NoLayout',
                title: "卖家登录",
                userType: 2
            },
        },
        {
            path: 'shopForget',
            component: () => import('@/views/seller/registered/shopForget.vue'),
            name: "shopForget",
            meta: {
                layout: 'NoLayout',
                title: "忘记密码",
                userType: 2
            },
        },

    ]
}