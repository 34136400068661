export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'open',
    name: "openz",
    meta: {
        title: '注册shopee卖家',
        layout: 'NoLayout',
        userType: 1,
        name: "首页"
    },
    children: [
        {
            path: 'open',
            component: () => import('@/views/seller/shop/open.vue'),
            name: "open",
            meta: {
                title: '注册shopee卖家',
                layout: 'LayShopOpen',
                userType: 1,
                validation: false,
            },
        }
    ]
}