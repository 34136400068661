<template>
  <div class="ShopOpenHead">
    <div style="height: 56px; weight: 100%"></div>
    <div class="continer">
      <div class="main-head">
        <div>

          <img
            @click="handleJunp"
            style="height: 33px; cursor: pointer"
            :src="$store.state.baseInfo.front_logo"
            alt=""
          />
        </div>
        <div>
          <el-dropdown class="el-drop">
            <div
              style="float: left; width: 100px; text-algin: center"
              class="el-dropdown-link"
            >
              简体中文
            </div>
            <i class="el-icon-arrow-down el-icon--right"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>中文</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShopOpenHead",
  methods: {
    handleJunp() {
      if (this.$route.path != "/home") {
        this.$router.replace("/");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.ShopOpenHead {
  .continer {
    // box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.6);
    background-color: #ee4d2d;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9;
  }
  .main-head {
    width: 1200px;
    height: 56px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .shopee-icon {
    display: block;
    width: 100px;
    height: 33px;
    fill: #fff;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  .el-drop {
    // width: 120px;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>