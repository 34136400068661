export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'registered',
    name: "registeredz",
    meta: {
        layout: 'NoLayout',
        title: "Info Collect",
    },
    children: [
        {
            path: 'registered',
            component: () => import('@/views/seller/registered/index.vue'),
            name: "registered",
            meta: {
                layout: 'LayRegistered',
                title: "Info Collect",
                type: 1,
                userType: 1,
            },
        },
        {
            path: 'mainForget',
            component: () => import('@/views/seller/registered/mainForget.vue'),
            name: "mainForget",
            meta: {
                layout: 'LayRegistered',
                title: "忘记密码",
                type: 1,
                userType: 1,
            },
        },
        {
            path: 'basicInfo',
            component: () => import('@/views/seller/registered/basicInfo.vue'),
            name: "basicInfo",
            meta: {
                layout: 'LayRegistered',
                title: "Main-account Apply",
                type: 2,
                userType: 1,

            },
        },

    ]
}