export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'home',
    name: "index",
    meta: {
        title: '首页',
        layout: 'LayHome',
        chatShow: true,

    },
    children: [
        {
            path: 'home',
            component: () => import('@/views/home/home.vue'),
            name: "home",
            meta: {
                layout: 'LayHome',
                userType: 3,
                title: "首页",
                chatShow: true
            }
        },
        {
            path: 'hotGoods',
            component: () => import('@/views/home/hotGoods.vue'),
            name: "hotGoods",
            meta: {
                layout: 'LayHome',
                userType: 3,
                pageType: true,
                chatShow: false
            }
        },
        {
            path: 'limitGoods',
            component: () => import('@/views/home/limitGoods.vue'),
            name: "limitGoods",
            meta: {
                layout: 'LayHome',
                pageType: true,
                chatShow: false
            }
        },
        {
            path: 'goodsSearchs',
            component: () => import('@/views/home/goodsSearchs.vue'),
            name: "goodsSearchs",
            meta: {
                layout: 'LayHome',
                pageType: true,
                userType: 3,
                chatShow: false
            }
        },
        {
            path: 'storeSearch',
            component: () => import('@/views/home/storeSearch.vue'),
            name: "storeSearch",
            meta: {
                layout: 'LayHome',
                pageType: true,
                userType: 3,
                chatShow: false
            }
        },
        {
            path: 'goodsSearch',
            component: () => import('@/views/home/goodsSearch.vue'),
            name: "goodsSearch",
            meta: {
                layout: 'LayHome',
                pageType: true,
                userType: 3,
                chatShow: false
            }
        },
        {
            path: 'allCategory',
            component: () => import('@/views/home/allCategory.vue'),
            name: "allCategory",
            meta: {
                layout: 'LayHome',
                pageType: true,
                userType: 3,
                chatShow: false
            }
        },
        {
            path: 'goodsInfo',
            component: () => import('@/views/home/goodsInfo.vue'),
            name: "goodsInfo",
            meta: {
                layout: 'LayHome',
                pageType: true,
                userType: 3,
                chatShow: true
            }
        },
        {
            path: 'allGoods',
            component: () => import('@/views/home/allGoods.vue'),
            name: "allGoods",
            meta: {
                layout: 'LayHome',
                pageType: true,
                userType: 3,
                chatShow: true
            }
        },
        {
            path: 'similarGoods',
            component: () => import('@/views/home/similarGoods.vue'),
            name: "similarGoods",
            meta: {
                layout: 'LayHome',
                pageType: true,
                userType: 3,
                chatShow: true
            }
        },
        {
            path: 'test',
            component: () => import('@/views/home/test.vue'),
            name: "test",
            meta: {
                layout: 'NoLayout',
                chatShow: true,
                title: "测试页"
            }
        },
        {
            path: 'error',
            component: () => import('@/views/home/alarmError.vue.vue'),
            name: "error",
            meta: {
                layout: 'NoLayout',
                chatShow: false,
                title: "错误页"
            }
        },
    ]
}