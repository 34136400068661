<template>
  <div>
    <BuyHead></BuyHead>
    <LayMain> </LayMain>
    <HomeFoot></HomeFoot>
  </div>
</template>
<script>
import BuyHead from "./components/head/BuyHead.vue";
import LayMain from "./components/LayMain.vue";
import HomeFoot from "./components/foot/HomeFoot";
export default {
  components: {
    HomeFoot,
    LayMain,
    BuyHead,
  },
};
</script>
<style lang="less" scoped>
</style>